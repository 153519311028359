import makeStyles from '@mui/styles/makeStyles';

export const useMenuButtonStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    ...theme.menuButtonFontStyles,
  },
  inactiveMenuItem: {
    cursor: 'default',
    '&.MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    ...theme.menuButtonFontStyles,
    fontWeight: 400,
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    padding: 0,
    width: '100%',
    justifyContent: 'left',
  },
  inactiveLink: {
    pointerEvents: 'none',
    textDecoration: 'none',
    color: 'lightgray',
    ...theme.menuButtonFontStyles,
  },
  selectedLink: {
    ...theme.palette.accentText,
    fontWeight: 'bold',
    ...theme.menuButtonFontStyles,
  },
  iconExternal: {
    fontSize: '.8rem',
    paddingLeft: '5px',
  },
}));
