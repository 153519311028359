import {
  M_A_COMPANY_BENCH_MARKING_PATH,
  M_A_OVERVIEW_PATH,
  M_A_TRANSACTION_ANALYTICS_PATH,
  M_A_TRANSACTION_TABLE_PATH,
} from '../../../../utils/constants';

import { ASSETS } from '../../../Filters/filterTypes';
import { AutoCompleteExcelLikeLocalData } from '../../../AutoComplete/AutoCompleteExcelLike/AutoCompleteExcelLike';
import Button from '@mui/material/Button';
import { FilterLabels } from '../../../Filters';
import { Link } from 'react-router-dom';
import { Notes } from '../Notes';
import React from 'react';
import { Slider } from '../../../basic/Slider';
import clsx from 'clsx';
import { usePageShellController } from './PageShell.controller';
import { useStyles } from './PageShell.style';

const SLIDER_INPUT_ID = 'effective-data-slider';

const TABS = [
  { name: 'OVERVIEW', url: M_A_OVERVIEW_PATH },
  { name: 'TRANSACTION TABLE', url: M_A_TRANSACTION_TABLE_PATH },
  { name: 'TRANSACTION ANALYTICS', url: M_A_TRANSACTION_ANALYTICS_PATH },
  { name: 'COMPANY ANALYSIS', url: M_A_COMPANY_BENCH_MARKING_PATH },
  //{ name: 'POTENTIAL M&A OPPORTUNITIES', url: M_A_POTENTIAL_OPPORTUNITIES_PATH },
];

export const PageShell = ({ children, filters }) => {
  const classes = useStyles();

  const sortEventTypeGroups = (a, b) => {
    const orderMapping = {
      Transactions: 1,
      Licensing: 2,
      Other: 3,
    };

    return orderMapping[a.group.name] - orderMapping[b.group.name];
  };

  const {
    marks,
    handleChangeValueSlider,
    currentUrlNode,
    typesAvailableList,
    countryAvailableList,
    companySellerAvailableList,
    companyBuyerAvailableList,
    assetAvailableList,
    updateCountryList,
    valueSlider,
    updateTypeList,
    updateCompanySellerList,
    updateCompanyBuyerList,
    updateAssetList,
    resetFilters,
  } = usePageShellController(filters);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.chips}>
          <div className={classes.filterSection}>
            <div className={classes.sliderWrapper}>
              <Slider
                className={classes.slider}
                id={SLIDER_INPUT_ID}
                value={[valueSlider.from, valueSlider.to]}
                onChange={handleChangeValueSlider}
                label="EFFECTIVE DATE (YEARS)"
                getAriaLabel={() => 'Effective Date (years)'}
                marks={marks}
                min={marks.length ? marks[0].value : undefined}
                step={1}
                max={marks.length ? marks[marks.length - 1].value : undefined}
                disable={!marks.length}
                width="220px"
              />
            </div>
            {!!countryAvailableList && (
              <AutoCompleteExcelLikeLocalData
                options={countryAvailableList}
                setOptions={updateCountryList}
                inModal={false}
                isLoading={!countryAvailableList}
                label={FilterLabels.countries}
                groupOptionKey="region"
                groupByName="Region"
              />
            )}
            {!!typesAvailableList && (
              <AutoCompleteExcelLikeLocalData
                options={typesAvailableList}
                setOptions={updateTypeList}
                inModal={false}
                isLoading={!typesAvailableList}
                label={FilterLabels.eventType}
                groupByName="Type"
                groupSorter={sortEventTypeGroups}
                groupsOpenedByDefault
                groupOptionKey="group"
              />
            )}
            {!!companySellerAvailableList && (
              <AutoCompleteExcelLikeLocalData
                options={companySellerAvailableList}
                setOptions={updateCompanySellerList}
                inModal={false}
                isLoading={!companySellerAvailableList}
                label={FilterLabels.companiesSeller}
              />
            )}
            {!!companyBuyerAvailableList && (
              <AutoCompleteExcelLikeLocalData
                options={companyBuyerAvailableList}
                setOptions={updateCompanyBuyerList}
                inModal={false}
                isLoading={!companyBuyerAvailableList}
                label={FilterLabels.companiesBuyer}
              />
            )}
            {!!assetAvailableList && (
              <AutoCompleteExcelLikeLocalData
                options={assetAvailableList}
                setOptions={updateAssetList}
                inModal={false}
                isLoading={!assetAvailableList}
                label={ASSETS.name}
              />
            )}
          </div>
          <div className={classes.buttonSection}>
            <Button variant="contained" className={classes.altButton} onClick={resetFilters}>
              Clear
            </Button>
          </div>
        </div>
        <div className={classes.tabs}>
          {TABS.map(({ name, url }) => (
            <Link
              className={clsx(classes.link, {
                [classes.currentLink]: url.indexOf(currentUrlNode) !== -1,
              })}
              key={name}
              to={url}
            >
              {name}
            </Link>
          ))}
        </div>
        <div className={classes.contentWrapper}>{children}</div>
      </div>
      <Notes />
    </div>
  );
};
