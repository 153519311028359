import { MA_DEAL_COUNT_BY_TYPE_QUERY } from '../operations';
import { TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM } from '../types';
import { useFilters } from '../../../FiltersContext';
import { useQuery } from '@apollo/client';

export const useGetMaDealCountByType = (by = TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM.COUNTRY) => {
  const { filterVariables } = useFilters();

  const { data, loading, error } = useQuery(MA_DEAL_COUNT_BY_TYPE_QUERY, {
    variables: { by, filters: filterVariables },
  });

  return {
    data: data?.maDealCountByType?.data || [],
    dealTypes: data?.maDealCountByType?.stacks || [],
    loading,
    error,
  };
};
