import { useFeatureFlags } from '../contexts/FeatureFlagsContext';
import { useMemo } from 'react';
import {
  CCUS_PATH,
  COUNTRY_OVERVIEWS_PATH,
  CUSTOMER_DOWNLOADS_PATH,
  DATA_DOWNLOADS_PATH,
  DEFAULT_PATH,
  GHG_PATH,
  M_A_BASIC_PATH_V2,
  M_A_COMPANY_BENCH_MARKING_PATH,
  M_A_OVERVIEW_PATH,
  M_A_TRANSACTION_ANALYTICS_PATH,
  M_A_TRANSACTION_TABLE_PATH,
  OIL_AND_GAS_MAP_PATH,
  OIL_AND_GAS_MAP_PATH_WITH_LATLONG,
  OIL_AND_GAS_MAP_PATH_WITH_LATLONG_AND_ZOOM,
  PORTFOLIO_ANALYSIS_PATH,
  RESERVES_DEFINITION_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  VALUATIONS_ASSET_PATH,
  VALUATIONS_ASSET_REPORT_PATH,
  VALUATIONS_PATH,
  VIDEO_TUTORIALS_PATH,
  WEA_ASSUMPTIONS_PATH,
} from './constants';
import LandingPage from '../components/LandingPage';
import CCUS from '../components/CCUS';
import GHG from '../components/GHG';
import PortfolioAnalysis from '../components/PortfolioAnalysis';
import OilAndGasMap from '../components/OilAndGasMap';
import Valuations from '../components/Valuations';
import AssetTemplate from '../components/Valuations/AssetTemplate';
import CountryOverviews from '../components/CountryOverviews';
import ShinyDownloads from '../components/ShinyDownloads';
import { MaPage } from '../components/M&A';
import { MaPage as MaPageNew } from '../components/M&A_NEW';
import SubscriberDownload from '../components/SubscriberDownload';
import GuidanceDocumentation from '../components/GuidanceDocumentation';
import Settings from '../components/Settings';
import { VideoTutorials } from '../pages/VideoTutorials';

export const useProtectedRoutes = () => {
  const { inDevelopment, inLocalEnv, maV2 } = useFeatureFlags();

  return useMemo(
    () => [
      // all protected routes paths and components
      {
        path: ROOT_PATH,
        Page: LandingPage,
      },
      {
        path: CCUS_PATH,
        Page: CCUS,
        forceNewTab: true,
      },
      {
        path: GHG_PATH,
        Page: GHG,
        forceNewTab: true,
      },
      {
        path: PORTFOLIO_ANALYSIS_PATH,
        Page: PortfolioAnalysis,
        forceNewTab: true,
      },
      {
        path: OIL_AND_GAS_MAP_PATH,
        Page: OilAndGasMap,
        forceNewTab: true,
      },
      {
        path: OIL_AND_GAS_MAP_PATH_WITH_LATLONG,
        Page: OilAndGasMap,
      },
      {
        path: OIL_AND_GAS_MAP_PATH_WITH_LATLONG_AND_ZOOM,
        Page: OilAndGasMap,
      },
      {
        path: VALUATIONS_PATH,
        Page: Valuations,
        forceNewTab: true,
      },
      {
        path: VALUATIONS_ASSET_PATH,
        Page: AssetTemplate,
      },
      {
        path: VALUATIONS_ASSET_REPORT_PATH,
        Page: AssetTemplate,
      },
      {
        path: COUNTRY_OVERVIEWS_PATH,
        Page: CountryOverviews,
      },
      ...(inLocalEnv
        ? [
          {
            path: DATA_DOWNLOADS_PATH,
            Page: ShinyDownloads,
          },
        ]
        : []),
      {
        path: M_A_OVERVIEW_PATH,
        Page: MaPage,
      },
      {
        path: M_A_TRANSACTION_TABLE_PATH,
        Page: MaPage,
      },
      {
        path: M_A_TRANSACTION_ANALYTICS_PATH,
        Page: MaPage,
      },
      {
        path: M_A_COMPANY_BENCH_MARKING_PATH,
        Page: MaPage,
      },
      ...(maV2
        ? [
          {
            path: M_A_BASIC_PATH_V2,
            Page: MaPageNew,
          },
        ]
        : []),
      {
        path: CUSTOMER_DOWNLOADS_PATH,
        Page: SubscriberDownload,
      },
      {
        path: RESERVES_DEFINITION_PATH,
        Page: GuidanceDocumentation,
      },
      {
        path: WEA_ASSUMPTIONS_PATH,
        Page: GuidanceDocumentation,
      },
      {
        path: SETTINGS_PATH,
        Page: Settings,
      },
      {
        path: VIDEO_TUTORIALS_PATH,
        Page: VideoTutorials,
      },
      {
        path: DEFAULT_PATH,
        Page: LandingPage,
      },
    ],
    [inDevelopment, inLocalEnv, maV2],
  );
};
