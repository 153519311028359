import {
  ADD_TO_WATCH_LIST_MUTATION,
  REMOVE_FROM_WATCH_LIST_MUTATION,
  WATCH_LIST_QUERY,
} from '../../LandingPage/SideBar/WatchList/operations';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_EVENT_ACTIONS,
  VALUATIONS_PATH,
} from '../../../utils/constants';
import {
  ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG,
  REPORT_TYPES,
  VALUATIONS_CONTEXT,
  VALUATIONS_GOOGLE_ANALYTICS_TAG,
} from '../constants';
import {
  GET_AVAILABLE_DOWNLOADS_QUERY,
  SECURE_COUNTRY_DOWNLOAD_QUERY,
} from '../../Downloads/operations';
import React, { useMemo } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import { CORE_DOWNLOAD_TYPE } from '../../CountryOverviews/constants';
import Checkbox from '@mui/material/Checkbox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import GetAppIcon from '@mui/icons-material/GetApp';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import { SECURE_REPORT_DOWNLOAD_QUERY } from '../../Valuations/AssetList/operations';
import Typography from '@mui/material/Typography';
import { formatDateShort } from '../../../utils/helpers';
import { getUserId } from '../../../utils/auth';
import { joinGqlErrorMessage } from '../../../utils/gqlErrors';
import makeStyles from '@mui/styles/makeStyles';
import { openDownloadUrl } from '../../Downloads/helpers';

const useStyles = makeStyles((theme) => ({
  reportsContainer: {
    width: '55%',
    paddingLeft: '25px',
    paddingTop: '10px',
  },
  reportsHeader: {
    fontSize: '33px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '28px',
    },
  },
  reportsHeaderMd: {
    fontSize: '27px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '19px',
    },
  },
  reportsHeaderSm: {
    fontSize: '21px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  valuationRadioGroup: {
    margin: '1px',
  },
  reportsButton: {
    ...theme.palette.button,
    margin: '1px',
    width: '160px',
    fontSize: theme.fontSizes.smallest,
  },
  reportsActionButton: {
    margin: '1px',
    ...theme.palette.actionCustom,
    width: '160px',
    fontSize: theme.fontSizes.smallest,
  },
  reportsCountryDownload: {
    margin: '1px',
    ...theme.palette.greenButton,
    width: '160px',
    fontSize: theme.fontSizes.smallest,
  },
  reportsChip: {
    margin: '1px',
    marginBottom: '5px',
    ...theme.palette.accent,
  },
  assetLabel: {
    whiteSpace: 'pre-line',
    color: '#507180',
  },
  backButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    textDecoration: 'none',
    color: '#337ab7',
  },
  downloadButtons: {
    marginTop: '-10px',
  },
  viewAssetButton: {
    marginTop: '-5px',
  },
}));

export default ({ asset, disabled, context, handleScroll, setUpdateWatchList, setSnack }) => {
  const classes = useStyles();
  const dimensions = asset
    ? { asset: asset.name, country: asset.country.name, company: 'NULL' }
    : {};

  const assetLegacyId = asset ? asset.legacyId : null;
  const isoCode = asset ? asset.country.isoCode : null;

  const { loading: watchListLoading, data: watchListData, refetch } = useQuery(WATCH_LIST_QUERY);

  const gaPage = useMemo(() => {
    return context === VALUATIONS_CONTEXT
      ? VALUATIONS_GOOGLE_ANALYTICS_TAG
      : ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG;
  }, [context]);

  const [getReportDownload] = useLazyQuery(SECURE_REPORT_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, filename, downloadMessage } = data.secureReportDownload;
      if (url && filename) {
        setSnack({
          open: true,
          message: `Your download should begin shortly.${downloadMessage}`,
          severity: 'success',
        });
        openDownloadUrl(url, filename);
      }
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  const [getCountryReport] = useLazyQuery(SECURE_COUNTRY_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, filename, downloadMessage } = data.secureCountryDownload;
      if (url) {
        setSnack({
          open: true,
          message: `Your download should begin shortly.${downloadMessage}`,
          severity: 'success',
        });
        openDownloadUrl(url, filename);
      }
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  const { loading: countryReportAvailableLoading, data: availableCountryDownloadsData } = useQuery(
    GET_AVAILABLE_DOWNLOADS_QUERY,
    {
      variables: {
        countryIsoCode: isoCode,
      },
    },
  );

  const hasCoreReport = () => {
    return (
      asset &&
      !countryReportAvailableLoading &&
      availableCountryDownloadsData?.availableCountryDownloads?.titles?.includes(CORE_DOWNLOAD_TYPE)
    );
  };

  const getReportDownloadLink = (reportType) => {
    const gaReportType =
      reportType === REPORT_TYPES.VALUATIONS_MODEL ? 'valuation_model' : 'asset_report';

    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: gaPage,
      event_action: ANALYTICS_EVENT_ACTIONS.download,
      event_label: gaReportType,
      userId: getUserId(),
      ...dimensions,
    });

    getReportDownload({
      variables: { assetLegacyId, isoCode, reportType },
    });
  };

  const copyURL = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: gaPage,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'share_asset',
      userId: getUserId(),
      ...dimensions,
    });
    const el = document.createElement('input');
    el.value = window.location.href;
    el.id = 'url-input';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    el.remove();
    setSnack({
      open: true,
      severity: 'success',
      message: 'Copied URL to clipboard!',
    });
  };

  const findWatchList = () => {
    if (watchListLoading) {
      return { found: false, id: -1 };
    }
    const listItem = watchListData
      ? watchListData.watchList?.find((watch) => watch.legacyId === `${assetLegacyId}`)
      : undefined;
    if (listItem === undefined) {
      return { found: false, id: -1 };
    }
    return { found: true, watchListLegacyId: listItem.legacyId };
  };
  const { found, watchListLegacyId } = findWatchList();

  const handleWatchListUpdateSuccess = (added) => {
    refetch();
    if (context === VALUATIONS_CONTEXT) setUpdateWatchList(true);
    setSnack({
      open: true,
      severity: 'success',
      message: `Successfully ${added ? 'added to' : 'removed from'} watch list!`,
    });
  };

  const [addToWatchList] = useMutation(ADD_TO_WATCH_LIST_MUTATION, {
    variables: { assetLegacyId: assetLegacyId, assetIsoCode: isoCode },
    onCompleted: () => {
      handleWatchListUpdateSuccess(true);
    },
    onError: ({ graphQLErrors }) => {
      setSnack({
        open: true,
        message: joinGqlErrorMessage(graphQLErrors),
        severity: 'error',
      });
    },
  });
  const [removeFromWatchList] = useMutation(REMOVE_FROM_WATCH_LIST_MUTATION, {
    variables: { assetLegacyId: watchListLegacyId, assetIsoCode: isoCode },
    onCompleted: () => {
      handleWatchListUpdateSuccess(false);
    },
    onError: ({ graphQLErrors }) => {
      setSnack({
        open: true,
        message: joinGqlErrorMessage(graphQLErrors),
        severity: 'error',
      });
    },
  });

  const handleAddToWatchList = (isPresent) => {
    if (isPresent) {
      removeFromWatchList();
    } else {
      gtag('event', ANALYTICS_EVENTS.event, {
        event_category: gaPage,
        event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
        event_label: 'save_to_watchlist',
        userId: getUserId(),
        ...dimensions,
      });
      addToWatchList();
    }
  };

  const downloadButtons = (
    <>
      <Grid
        className={hasCoreReport() ? classes.downloadButtons : classes.viewAssetButton}
        size={6}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Button
          id="valuations-asset-valuation-model-button"
          onClick={() => {
            getReportDownloadLink(REPORT_TYPES.VALUATIONS_MODEL);
          }}
          disabled={disabled}
          className={classes.reportsButton}
          variant="contained"
        >
          <GetAppIcon fontSize="small" />
          &nbsp;valuation model
        </Button>
      </Grid>
      {hasCoreReport() && (
        <Grid
          key="valuationButtonCoutryOverviewReport"
          className={hasCoreReport() ? classes.downloadButtons : classes.viewAssetButton}
          size={6}
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <Button
            id="country-overview-report-button"
            disabled={disabled}
            className={classes.reportsCountryDownload}
            variant="contained"
            underline="none"
            onClick={() => {
              gtag('event', ANALYTICS_EVENTS.event, {
                event_category: gaPage,
                event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                event_label: 'country_overview_download',
                userId: getUserId(),
                ...dimensions,
              });
              getCountryReport({
                variables: { downloadType: CORE_DOWNLOAD_TYPE, countryIsoCode: isoCode },
              });
            }}
          >
            <GetAppIcon fontSize="small" />
            country overview&nbsp;
          </Button>
        </Grid>
      )}
      <Grid
        className={hasCoreReport() ? classes.downloadButtons : classes.viewAssetButton}
        size={6}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Button
          onClick={() => {
            context !== VALUATIONS_CONTEXT && handleScroll();
            getReportDownloadLink(REPORT_TYPES.ASSET_REPORT);
          }}
          disabled={disabled}
          className={classes.reportsButton}
          variant="contained"
        >
          <GetAppIcon fontSize="small" />
          &nbsp;asset report
        </Button>
      </Grid>
    </>
  );

  const assetTemplateItems = (
    <>
      <Grid container size={12}>
        <Grid key="assetTemplateAssetCountry" size={9}>
          <Typography className={classes.assetLabel} variant="overline">
            {disabled ? '-' : asset.country.displayName}
          </Typography>
        </Grid>
        <Grid key="assetTemplateBackButton" size={3}>
          {disabled ? null : (
            <Typography
              className={classes.backButton}
              variant="overline"
              component={Link}
              to={VALUATIONS_PATH}
            >
              BACK TO SEARCH
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid key="assetTemplateAssetHeader" size={12}>
        <Typography className={classes.reportsHeader} variant="h4">
          {disabled ? '-' : asset.displayName}
        </Typography>
      </Grid>
      <Grid key="assetTemplateUpdated" size={12}>
        <Typography className={classes.assetLabel} variant="overline">
          updated:{' '}
          {asset && asset.reportUpdatedDate ? formatDateShort(asset.reportUpdatedDate) : '-'}
        </Typography>
      </Grid>
      <Grid
        key="assetTemplateSaveWatchList"
        container
        size={12}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <FormControl className={classes.valuationRadioGroup} component="fieldset">
          <FormControlLabel
            disabled={disabled}
            value={true}
            control={<Checkbox checked={found} onClick={() => handleAddToWatchList(found)} />}
            label="SAVE TO WATCHLIST"
          />
        </FormControl>
      </Grid>
      <Grid
        key="assetTemplateReportChip"
        container
        size={12}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        {asset && asset.mAndAOpportunity ? (
          <Chip disabled={disabled} className={classes.reportsChip} label="M&A OPPORTUNITY" />
        ) : null}
      </Grid>
      <Grid
        container
        size={12}
        spacing={2}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        {downloadButtons}
        <Grid
          key="assetTemplateButtonShare"
          container
          className={hasCoreReport() ? classes.downloadButtons : classes.viewAssetButton}
          size={6}
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <Button onClick={copyURL} className={classes.reportsActionButton} variant="contained">
            <LinkIcon
              sx={{
                fontSize: 'small',
              }}
            />
            &nbsp;share
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const getAssetHeaderClass = () => {
    if (disabled) return;
    const size = asset.displayName.length;
    if (size > 30) {
      return classes.reportsHeaderSm;
    } else if (size > 20) {
      return classes.reportsHeaderMd;
    }
    return classes.reportsHeader;
  };

  const valuationItems = (
    <>
      <Grid key="valuationAssetHeader" size={12}>
        <Typography className={getAssetHeaderClass()} variant={'h4'}>
          {disabled ? '-' : asset.displayName}
        </Typography>
        <Grid key="assetTemplateUpdated" size={12}>
          <Typography className={classes.assetLabel} variant="overline">
            updated:{' '}
            {asset && asset.reportUpdatedDate ? formatDateShort(asset.reportUpdatedDate) : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        key="valuationSaveWatchList"
        container
        size={asset && asset.mAndAOpportunity ? 6 : 12}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <FormControl
          id="valuations-save-to-watch-list-checkbox"
          className={classes.valuationRadioGroup}
          component="fieldset"
        >
          <FormControlLabel
            disabled={disabled}
            value={true}
            control={<Checkbox checked={found} onClick={() => handleAddToWatchList(found)} />}
            label="SAVE TO WATCHLIST"
          />
        </FormControl>
      </Grid>
      {!!(asset && asset.mAndAOpportunity) && (
        <Grid
          key="valuationAssetChip"
          container
          size={6}
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <Chip disabled={disabled} className={classes.reportsChip} label="M&A OPPORTUNITY" />
        </Grid>
      )}
      <Grid
        container
        size={12}
        spacing={2}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        {downloadButtons}
        <Grid
          key="valuationButtonViewAsset"
          className={hasCoreReport() ? classes.downloadButtons : classes.viewAssetButton}
          size={6}
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <Button
            id="valuations-view-asset-page-button"
            disabled={disabled}
            className={classes.reportsActionButton}
            variant="contained"
            underline="none"
            onClick={() => {
              gtag('event', ANALYTICS_EVENTS.event, {
                event_category: gaPage,
                event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                event_label: 'view_asset_page',
                userId: getUserId(),
                ...dimensions,
              });
              window.open(`${VALUATIONS_PATH}/${isoCode}/${assetLegacyId}`, '_blank');
            }}
          >
            view asset page&nbsp;
            <ChevronRightIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid className={classes.reportsContainer} container>
      {context === VALUATIONS_CONTEXT ? valuationItems : assetTemplateItems}
    </Grid>
  );
};
