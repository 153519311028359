import {
  RESERVES_AGGREGATES_TYPE_ENUM,
  RESERVES_AGGREGATE_BY_TYPE_ENUM,
  RESERVES_STACK_BY_TYPE_ENUM,
  RESERVES_SUM_FIELD,
} from '../types';

import { MA_RESERVES_ACQUIRED_STACKED_QUERY } from '../operations';
import { useFilters } from '../../../FiltersContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetMaReservesAggregatesStacked = (
  by = RESERVES_AGGREGATE_BY_TYPE_ENUM.BUYER,
  stackBy = RESERVES_STACK_BY_TYPE_ENUM.BUYER,
  type = RESERVES_AGGREGATES_TYPE_ENUM.BUYER,
  skip = true,
) => {
  const { filterVariables } = useFilters();

  const queryVariables = useMemo(() => {
    // If aggregating by Year, use the default orderBy and order of the query
    const { orderBy, order } =
      by === RESERVES_AGGREGATE_BY_TYPE_ENUM.YEAR
        ? { orderBy: undefined, order: undefined }
        : { orderBy: RESERVES_SUM_FIELD, order: 'DESC' };

    return {
      by,
      stackBy,
      filters: {
        ...filterVariables,
        hasBuyers: type === RESERVES_AGGREGATES_TYPE_ENUM.BUYER,
        hasSellers: type === RESERVES_AGGREGATES_TYPE_ENUM.SELLER,
      },
      order,
      orderBy,
      fields: [RESERVES_SUM_FIELD],
    };
  }, [filterVariables, by, type, stackBy]);

  const { data, loading, error } = useQuery(MA_RESERVES_ACQUIRED_STACKED_QUERY, {
    variables: queryVariables,
    skip,
  });

  return {
    data: data?.maTransactionsAggregatesStacked?.data || [],
    stacks: data?.maTransactionsAggregatesStacked?.stacks || [],
    loading,
    error,
  };
};
