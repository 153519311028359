import { MA_DEALS_QUERY } from '../operations';
import { useFilters } from '../../../FiltersContext';
import { useQuery } from '@apollo/client';

export const useGetDeals = () => {
  const { filterVariables } = useFilters();
  const { data, loading, error } = useQuery(MA_DEALS_QUERY, {
    variables: { filters: filterVariables },
  });

  return {
    data: data?.maDeals || [],
    loading,
    error,
  };
};
