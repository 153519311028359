import { gql } from '@apollo/client';

export const MA_RESERVES_ACQUIRED_QUERY = gql`
  query maTransactionsAggregates(
    $by: MaAggregateByEnum!
    $filters: MaFilters
    $orderBy: String
    $order: String
  ) {
    maTransactionsAggregates(by: $by, filters: $filters, orderBy: $orderBy, order: $order) {
      type
      group
      reservesSum
      acquisitionCostSum
    }
  }
`;

export const MA_RESERVES_ACQUIRED_STACKED_QUERY = gql`
  query maTransactionsAggregatesStacked(
    $by: MaAggregateByEnum!
    $stackBy: MaStackByEnum!
    $fields: [String!]!
    $filters: MaFilters
    $orderBy: String
    $order: String
  ) {
    maTransactionsAggregatesStacked(
      by: $by
      stackBy: $stackBy
      fields: $fields
      filters: $filters
      orderBy: $orderBy
      order: $order
    ) {
      stacks
      data {
        type
        group
        values
      }
    }
  }
`;
