import makeStyles from '@mui/styles/makeStyles';

export const useCustomOptionsStyles = makeStyles(() => ({
  customOptionWrapper: {
    position: 'fixed',
    bottom: 0,
    borderTop: '1px solid lightgray',
    width: '100%',
  },
  noteLabel: {
    fontSize: '8px',
    color: 'grey',
    width: '100%',
    height: '100%',
    padding: '6px 16px',
    boxSizing: 'border-box',
  },
  noteLabelHidden: {
    display: 'none',
  },
}));
