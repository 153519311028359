import React, { useEffect, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.altScrollbarBlue,
    padding: '5px 15px',
    margin: 0,
    alignItems: 'flex-start',
    height: '100%',
    overflow: 'auto',
  },
  check: {
    padding: '5px',
  },
  children: {
    paddingLeft: '16px',
  },
  parentAndChildContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

/**
 * CheckList component for displaying a list of checkboxes
 * @param {object} props
 * @param {function} props.callback - function to call when a checkbox is clicked
 * @param {array} props.checked - list of checked items
 * @param {boolean} props.includeAll - whether to include an "All" checkbox
 * @param {boolean} props.allReturnsNone - whether to return an empty array when all is checked
 * @param {boolean} props.itemIsDisabledParams - object parameters to be passed to isDisabled function for each list item
 * @param {boolean} props.selectAllOnNoneChecked - whether to select all items when none are checked
 * @param {boolean} props.allSelectableWhenChildrenDisabled - whether to allow user to select all when all items are disabled
 * @param {array} props.list = list of all items to display
 * @param {object} props.styles - styles to apply to the list
 * @param {object} props.styles.checkListRoot - styles to apply to the root of the list
 * @param {object} props.customItems - items we want hard coded that aren't found in the db.
 *                   (object of objects where initial key is the idx position the item should be in
 *                   when rendered). e.g. { 1: { name: 'Pre-sanction Development', isParent: true } },
 *                   where name = name of item, isParent = signifies parent
 * @param {function} props.getParent - function to check if an item in the list has a parent.
 *                  Return name of parent or null otherwise
 *                  e.g. (item) => item[nameKey].includes('Pre-sanction') ? 'Pre-sanction Development' : false
 *                  (rule to follow when setting child).
 * @param {object} props.combinedItems - items that will be combined into a single item in the list
 *                  e.g. { Deepwater: ['Pre-salt'] } // Deepwater will be shown in the list and Pre-salt will be hidden,
 *                 but when Deepwater is checked, Pre-salt will be checked as well.
 */
export default ({
  callback,
  checked,
  includeAll,
  isCheckedCallback,
  isDisabled,
  allReturnsNone,
  itemIsDisabledParams,
  selectAllOnNoneChecked,
  allSelectableWhenChildrenDisabled,
  label,
  labelPlacement = 'end',
  list,
  nameKey = 'name',
  styles = {},
  customItems = {},
  combinedItems,
  getParent = () => null,
  parentItemsToInclude = [],
  color = 'secondary',
}) => {
  const classes = useStyles();
  const [renderedList, setRenderedList] = useState([]);
  const [children, setChildren] = useState({});

  // Check if item is disabled based on if it's property is a function or bool
  const checkIsDisabled = (item) =>
    typeof item.isDisabled === 'function' ? item.isDisabled(itemIsDisabledParams) : item.isDisabled;

  // Recursively check if an item is enabled (parent or child):
  const isItemEnabled = (list, item) => {
    const listItem = list?.find((listItem) => listItem[nameKey] === item[nameKey]);
    if (list && !listItem) {
      const parent = getParent(item);
      const childrenItems = children[parent];
      return isItemEnabled(childrenItems, item);
    }

    return listItem && !checkIsDisabled(listItem);
  };

  const enabledList = list.filter((item) => isItemEnabled(list, item)) || [];
  const isAll =
    checked?.length === enabledList?.length || // all items are checked
    (checked?.length === list?.length && allSelectableWhenChildrenDisabled) || // all items are checked even if children are disabled
    (selectAllOnNoneChecked && checked?.length === 0); // none are checked and we want to select all

  // checks if item is checked
  const isItemChecked = (item) => checked?.find((c) => c[nameKey] === item[nameKey]);

  // checks if all children of parent are checked/disabled
  const isParentChecked = (parent) =>
    children[parent].every((child) => checkIsDisabled(child) || isItemChecked(child));

  // taking into account props, children, and parent should the component render with a check
  const isChecked = (listItem) => {
    const { name, isParent } = listItem;
    if (checkIsDisabled(listItem)) return false; // if disabled, always return false

    // if all checkbox is included
    if (includeAll) {
      // render check for item if not all items are selected and (item is checked or all children are checked)
      return !isAll && (isParent ? isParentChecked(name) : !!isItemChecked(listItem));
    } else {
      // if all checkbox is not included
      // render check for item if all items are selected or (item is checked or all children are checked)
      return isAll || (isParent ? isParentChecked(name) : !!isItemChecked(listItem));
    }
  };

  // returns all combined items (if any), including the current item:
  const getCombinedItems = (item) => [
    item,
    ...(list || []).filter((l) => combinedItems?.[item[nameKey]]?.includes(l[nameKey])),
  ];

  const addItemToChecked = (item) => {
    const includeParentItem = parentItemsToInclude.includes(item[nameKey]);
    if (isAll) {
      // if all was previously checked newList will contain only the newly selected item(s)
      if (item.isParent || includeParentItem) {
        const childrenToAdd = children[item[nameKey]]
          ?.filter((child) => !checkIsDisabled(child))
          .map((child) => getCombinedItems(child))
          .flat();

        // if item is a parent and we want to include it in the checked list
        return includeParentItem ? [...childrenToAdd, ...getCombinedItems(item)] : childrenToAdd;
      } else {
        return getCombinedItems(item);
      }
    } else if (item.isParent || includeParentItem) {
      // if selected item is parent only add children not already selected
      const childrenToAdd = children[item[nameKey]]
        .filter((child) => !isItemChecked(child) && !checkIsDisabled(child))
        .map((child) => getCombinedItems(child))
        .flat();
      const childrenAdded = [...checked, ...childrenToAdd];

      // if item is a parent and we want to include it in the checked list
      return includeParentItem ? [...childrenAdded, ...getCombinedItems(item)] : childrenAdded;
    } else {
      // just add item to checked otherwise
      return [...checked, ...getCombinedItems(item)];
    }
  };

  const removeItemFromChecked = (item) => {
    if (item.isParent || parentItemsToInclude.includes(item[nameKey])) {
      // remove children
      return checked?.filter((c) => getParent(c) !== item[nameKey] && c[nameKey] !== item[nameKey]);
    } else {
      // remove item
      return checked?.filter((c) => getCombinedItems(item).every((i) => i[nameKey] !== c[nameKey]));
    }
  };

  const handleClick = (e, item) => {
    let newList;
    if (e.target.checked) {
      // filter added
      newList = addItemToChecked(item);
    } else {
      // filter removed
      newList = removeItemFromChecked(item);
      // if select all on none checked is true, check all items
      if (selectAllOnNoneChecked && newList.length === 0) {
        newList = [...list];
      }
    }
    callback(newList, item);
  };

  useEffect(() => {
    const listToRender = [];
    const children = {};
    const items = combinedItems
      ? list.filter((a) => !Object.values(combinedItems).flat().includes(a[nameKey]))
      : list;
    items.forEach((listItem, idx) => {
      // insert customItems in place
      if (customItems && customItems[idx]) {
        // check if customItem is the same as the current listItem
        // if so combine the objects and move onto next item
        const customItem = customItems[idx];
        if (customItem[nameKey] === listItem[nameKey]) {
          listToRender.push({ ...customItem, ...listItem });
          return;
        }
        listToRender.push(customItem);
      }
      // check if item is a child
      // if so push to children object
      const parent = getParent(listItem);
      if (parent) {
        children[parent] ? children[parent].push(listItem) : (children[parent] = [listItem]);
      } else {
        // neither a parent or child
        listToRender.push(listItem);
      }
    });
    setChildren(children);
    setRenderedList(listToRender);
  }, [list]);

  const handleAllClick = () => {
    // Return the list with all enabled items:
    const allEnabled = list.filter((item) => isItemEnabled(list, item));
    callback(allReturnsNone ? [] : allEnabled);
  };

  return (
    <Grid
      className={styles.checkListRoot ? clsx(classes.root, styles.checkListRoot) : classes.root}
      container
      spacing={1}
      direction="column"
      sx={{
        alignItems: 'center',
      }}
      size={12}
    >
      {label && (
        <Typography className={clsx({ [styles.label]: !!styles.label })} variant="overline">
          {label}
        </Typography>
      )}
      <FormControl
        className={clsx({
          [styles.checkListForm]: !!styles.checkListForm,
        })}
        disabled={isDisabled}
        component="fieldset"
      >
        {includeAll && (
          <FormControlLabel
            className={styles.checkboxLabel}
            value={true}
            control={
              <Checkbox
                color={color}
                className={clsx({ [classes.check]: true, [styles.check]: !!styles.check })}
                checked={isAll}
                onClick={handleAllClick}
              />
            }
            label="All"
          />
        )}
        {renderedList.map((listItem) => {
          const { displayName, isParent } = listItem;
          const name = listItem[nameKey];

          const childrenItems = children[name];
          // Disable the checkboxes if the parent is disabled or if all the children are disabled:
          const isParentDisabled =
            checkIsDisabled(listItem) ||
            (isParent && childrenItems?.every((c) => checkIsDisabled(c)));
          return (
            <div
              key={`checklist-${listItem[nameKey]}`}
              className={clsx({
                [classes.parentAndChildContainer]: true,
                [styles.parentAndChildContainer]: !!styles.parentAndChildContainer,
              })}
            >
              <Tooltip title={listItem.tooltip || ''}>
                <FormControlLabel
                  className={styles.checkboxLabel}
                  value={true}
                  control={
                    <Checkbox
                      color={color}
                      className={clsx({ [classes.check]: true, [styles.check]: !!styles.check })}
                      checked={
                        isCheckedCallback ? isCheckedCallback(listItem) : isChecked(listItem)
                      }
                      onClick={(e) => handleClick(e, listItem)}
                    />
                  }
                  label={displayName || name}
                  labelPlacement={labelPlacement}
                  disabled={isParentDisabled}
                />
              </Tooltip>
              {childrenItems && (
                <FormControl className={classes.children} component="fieldset">
                  {childrenItems.map((childItem) => {
                    // Disable the checkbox if either the parent or the child is disabled.
                    // Also don't check the checkbox if the parent is disabled.
                    const isChildDisabled = checkIsDisabled(childItem) || isParentDisabled;
                    const isChildChecked = isChildDisabled
                      ? false
                      : includeAll
                        ? !isAll && !!checked?.find((c) => c[nameKey] === childItem[nameKey])
                        : !!checked?.find((c) => c[nameKey] === childItem[nameKey]);
                    return (
                      <FormControlLabel
                        key={`checklist-child-${childItem[nameKey]}`}
                        value={true}
                        control={
                          <Checkbox
                            color={color}
                            className={clsx({
                              [classes.check]: true,
                              [styles.check]: !!styles.check,
                            })}
                            checked={isChildChecked}
                            onClick={(e) => handleClick(e, childItem)}
                          />
                        }
                        label={childItem.displayName || childItem[nameKey]}
                        labelPlacement={labelPlacement}
                        disabled={isChildDisabled}
                      />
                    );
                  })}
                </FormControl>
              )}
            </div>
          );
        })}
      </FormControl>
    </Grid>
  );
};
