import React, { useMemo } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SelectableInfoItem from './selectableInfoItem';
import { getTemplate } from './template';
import { snakeToCamel } from '../../../utils/helpers';
import { MAP_LAYER_IDS } from '../../Map/Utils/constants';
import SummaryListItem from '../../SummaryList/summaryListItem';
import { getLabelUnitValueFromTemplate } from '../../SummaryList/SummaryItems/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapInfoSection,
    margin: 0,
    '& tr': {
      margin: 0,
      padding: '3px',
    },
    '& tr:nth-child(odd)': {
      backgroundColor: theme.palette.baseColors.cloudGray.c75,
    },
  },
  groupDivider: {
    border: 'none',
    padding: 0,
  },
  groupLabel: {
    fontWeight: 'bold',
    width: '130px',
    padding: '15px 3px 3px',
  },
  groupRoot: {
    ...theme.palette.mapInfoSection,
    margin: 0,
    paddingBottom: '10px',
  },
}));

const Divider = () => (
  <div
    style={{
      marginTop: '5px',
      marginBottom: '5px',
    }}
  />
);

export default ({
  layerCountry,
  layerName,
  loading,
  item,
  data,
  tab,
  changeQueriedItem,
  showParticipationHistory,
  setShowParticipationHistory,
}) => {
  const classes = useStyles();
  const template = useMemo(
    () =>
      layerName === MAP_LAYER_IDS.basins
        ? getTemplate('basin')
        : getTemplate(item.entityType, layerCountry, tab, changeQueriedItem),
    [item, layerCountry, tab],
  );

  const getItemRow = (templateItem, itemData, key) => {
    if (!itemData) return null;
    const { accessor, getEntity, label, template, type } = templateItem;
    let extractedLabel, unit, value;

    const extractValue = (accessor) => {
      if (typeof accessor === 'function') {
        return accessor(itemData);
      }
      return itemData[accessor];
    };

    switch (type) {
      case 'graph': {
        return (
          <TableRow key={`${key}-graph`} style={{ display: 'block' }}>
            {extractValue(accessor)}
          </TableRow>
        );
      }
      case 'group': {
        const entityToExtract = getEntity ? getEntity(itemData) : itemData;
        const rows = template
          .map((t, i) => getItemRow(t, entityToExtract, `${key}-group-${i}`, i === 0))
          .filter((r) => !!r);
        return rows.length
          ? [
              <>
                {label ? (
                  <TableRow key={`${key}-group-label`}>
                    <TableCell className={classes.groupLabel}>{label}</TableCell>
                  </TableRow>
                ) : null}
              </>,
              ...rows,
            ]
          : null;
      }
      case 'multiGroup': {
        const groups = accessor(itemData);
        const rows = [];
        groups?.forEach((g, i) => {
          g = { ...g, index: i };
          rows.push(
            <TableHead key={`${key}-multiGroup-${i}`} className={classes.groupRoot}>
              {template.map((t, j) => getItemRow(t, g, `${key}-multiGroup-${i}-${j}`, i === 0))}
            </TableHead>,
          );
        });
        return groups?.length
          ? [
              <TableRow key={`${key}-multiGroup-label`}>
                {rows.length &&
                  (label ? (
                    <TableCell className={classes.groupLabel}>{label}</TableCell>
                  ) : (
                    <TableCell className={classes.groupDivider} />
                  ))}
              </TableRow>,
              ...rows,
            ]
          : null;
      }
      case 'break': {
        return <Divider key={key} />;
      }
      case 'selectable': {
        extractedLabel = extractValue(label);
        value = extractValue(accessor);
        return <SelectableInfoItem key={key} label={extractedLabel} valuesAndActions={value} />;
      }
      default: {
        ({
          label: extractedLabel,
          unit,
          value,
        } = getLabelUnitValueFromTemplate(templateItem, itemData));
      }
    }

    return (
      value && (
        <SummaryListItem
          variant="table"
          key={key}
          item={item}
          label={extractedLabel}
          unit={unit}
          value={value}
          setShowParticipationHistory={setShowParticipationHistory}
          showParticipationHistory={showParticipationHistory}
        />
      )
    );
  };

  return (
    <Table style={{ display: 'block' }}>
      <TableHead className={classes.root}>
        {layerName === MAP_LAYER_IDS.basins
          ? template.map((t, i) => getItemRow(t, item, `info-${i}`))
          : !loading &&
            item &&
            data &&
            data[snakeToCamel(item.entityType)] &&
            template.map((t, i) => getItemRow(t, Object.values(data)[0], `info-${i}`)).flat()}
      </TableHead>
    </Table>
  );
};
