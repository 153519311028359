import { MA_FILTERS_SUMMARY_QUERY } from '../operations';
import { useQuery } from '@apollo/client';

export const useGetMaFiltersSummary = () => {
  const { data, previousData, loading, error } = useQuery(MA_FILTERS_SUMMARY_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const maTransactionsSummary = loading
    ? previousData?.maTransactionsSummary
    : data?.maTransactionsSummary;

  return {
    data: maTransactionsSummary || {},
    loading,
    error,
  };
};
