import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  filterWrapper: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.baseColors.gray.c100}`,
  },
  filterItem: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
    '& > div': {
      width: '100%',
    },
  },
  buttonSection: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: '0.5rem',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '180px',
      minWidth: '58px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      paddingBottom: '.5rem',
      rowGap: '1rem',
      minWidth: 'unset',
    },
    paddingRight: '13px',
  },
  allFiltersButton: {
    ...theme.palette.button,
    height: '36px',
    width: 'fit-content',
    alignSelf: 'center',
  },
  clearButton: {
    ...theme.palette.altButton,
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  filterSection: {
    display: 'flex',
    flexGrow: '1',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0.5rem 0',
  },
  sliderWrapper: {
    paddingRight: '1rem',
    paddingLeft: '1.5rem',
    flex: 1,
  },
  slider: {
    width: '100%',
    height: '2px',

    '& .MuiSlider-thumb': {
      width: '10px',
      height: '10px',
    },
  },
  sliderLabel: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
  },
}));
