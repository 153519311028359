import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { FilterKeys } from '../Filters';
import { MaFilterKeys } from './components/Filters/types';

const FiltersContext = createContext();

export const MA_CONTEXT = 'MA';
export const INITIAL_YEARS = [2010, new Date().getFullYear()];
const INITIAL_RANGE = [0, 100];

const INITIAL_VALUES = {
  [FilterKeys.assets]: [],
  [FilterKeys.basins]: [],
  [FilterKeys.countries]: [],
  [FilterKeys.companiesBuyer]: [],
  [FilterKeys.companiesSeller]: [],
  [FilterKeys.eventType]: [],
  [FilterKeys.hasMaTransactions]: true,
  [FilterKeys.hydrocarbons]: [],
  [FilterKeys.shoreStatuses]: [],
  [FilterKeys.developmentStatuses]: [],
  [MaFilterKeys.dates]: INITIAL_YEARS,
  [MaFilterKeys.valuation]: INITIAL_RANGE,
  [MaFilterKeys.reserves]: INITIAL_RANGE,
  [MaFilterKeys.production]: INITIAL_RANGE,
  [MaFilterKeys.emissions]: INITIAL_RANGE,
};

const getInitialFilters = () => {
  const sessionFilters = sessionStorage.getItem(MA_CONTEXT);
  if (sessionFilters) {
    // Remove dates from the sessionStorage
    return { ...JSON.parse(sessionFilters), [MaFilterKeys.dates]: INITIAL_YEARS };
  }
  return INITIAL_VALUES;
};

const FiltersProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [yearsRange, setYearsRange] = useState(INITIAL_YEARS);
  const [valuationRange, setValuationRange] = useState(INITIAL_RANGE);
  const [reservesRange, setReservesRange] = useState(INITIAL_RANGE);
  const [productionRange, setProductionRange] = useState(INITIAL_RANGE);
  const [emissionsRange, setEmissionsRange] = useState(INITIAL_RANGE);
  const [filters, setFilters] = useState(getInitialFilters());

  useEffect(() => {
    sessionStorage.setItem(MA_CONTEXT, JSON.stringify(filters));
  }, [filters]);

  const resetFilters = () => {
    setLoading(true);
    setFilters({
      ...INITIAL_VALUES,
      [MaFilterKeys.dates]: [Math.max(INITIAL_YEARS[0], yearsRange[0]), yearsRange[1]], // Keep 2010 as the default start year
      [MaFilterKeys.valuation]: valuationRange,
      [MaFilterKeys.reserves]: reservesRange,
      [MaFilterKeys.production]: productionRange,
      [MaFilterKeys.emissions]: emissionsRange,
    });
  };

  useEffect(() => {
    // Had to create a loading state to force the dateSlider component to re-render
    // when resetting the filters, otherwise the component was showing the old values,
    // even though the filters state was updated.
    // I think this is an issue with the library.
    // TODO: in the future, after updating MUI, we need to revisit this to see if it can be removed.
    setLoading(false);
  }, [filters]);

  const filterVariables = useMemo(() => {
    const [startYear, endYear] = filters?.[MaFilterKeys.dates] || [];
    const [minValuation, maxValuation] = filters?.[MaFilterKeys.valuation] || [];
    const [minReserves, maxReserves] = filters?.[MaFilterKeys.reserves] || [];
    const [minProduction, maxProduction] = filters?.[MaFilterKeys.production] || [];
    const [minEmissions, maxEmissions] = filters?.[MaFilterKeys.emissions] || [];

    return {
      assets: filters[FilterKeys.assets]?.map((a) => Number(a.entityId)) || [],
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
      buyers: filters[FilterKeys.companiesBuyer]?.map((b) => Number(b.id)) || [],
      sellers: filters[FilterKeys.companiesSeller]?.map((s) => Number(s.id)) || [],
      eventTypes: filters[FilterKeys.eventType]?.map((e) => e.name) || [],
      basins: filters[FilterKeys.basins]?.map((b) => Number(b.id)) || [],
      hydrocarbonTypes: [...new Set(filters[FilterKeys.hydrocarbons]?.map((h) => h.type))] || [],
      oilTypes: [...new Set(filters[FilterKeys.hydrocarbons]?.map((h) => h.oil))] || [],
      developmentStatuses: filters[FilterKeys.developmentStatuses]?.map((d) => Number(d.id)) || [],
      shoreStatuses: filters[FilterKeys.shoreStatuses]?.map((s) => Number(s.id)) || [],
      startYear,
      endYear,
      minValuation,
      maxValuation,
      minReserves,
      maxReserves,
      minProduction,
      maxProduction,
      minEmissions,
      maxEmissions,
    };
  }, [filters]);

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
        yearsRange,
        setYearsRange,
        valuationRange: valuationRange,
        setValuationRange: setValuationRange,
        reservesRange,
        setReservesRange,
        productionRange,
        setProductionRange,
        emissionsRange,
        setEmissionsRange,
        loading,
        setLoading,
        refresh,
        setRefresh,
        filterVariables,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

const useFilters = () => useContext(FiltersContext);

export { FiltersProvider, useFilters };
