import React from 'react';
import { Button, TableCell } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ParticipationHistoryTable } from '../ParticipationHistoryTable';
import { useGetHistoricalParticipation } from '../../OilAndGasMap/Info/hooks/useGetHistoricalParticipation';
import { useStyles } from './ParticipationCell.style.js';

export const ParticipationCell = ({
  item,
  showParticipationHistory,
  classTableCell,
  displayedValue,
  setShowParticipationHistory,
}) => {
  const classes = useStyles();
  const { data: historicalParticipation, totalCount } = useGetHistoricalParticipation(item);
  const moreParticipationThanDisplayed = totalCount > historicalParticipation.length;
  return (
    <>
      <TableCell className={classTableCell}>
        {displayedValue}
        {historicalParticipation.length > 0 && (
          <div>
            <Button
              className={classes.participationButton}
              variant="text"
              onClick={() => setShowParticipationHistory((v) => !v)}
              endIcon={showParticipationHistory ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {showParticipationHistory ? 'Hide' : 'Show'}
              {' history'}
            </Button>
          </div>
        )}
      </TableCell>
      {historicalParticipation.length > 0 && showParticipationHistory && (
        <ParticipationHistoryTable
          historicalParticipation={historicalParticipation}
          moreParticipationThanDisplayed={moreParticipationThanDisplayed}
        />
      )}
    </>
  );
};
