import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';
import {
  AdvancedFilterCategories,
  defaultCheckBoxFilterTypes,
  defaultCheckListFilterTypes,
  defaultFilterTypes,
  defaultMinMaxFilterTypes,
} from './filterTypes';
import React, { useEffect, useState } from 'react';
import { analytics, dimensions } from '../../utils/analytics';

import Button from '@mui/material/Button';
import CheckBoxItem from './checkBoxItem';
import CheckListGroup from './checkListGroup';
import CheckListItem from './checkListItem';
import Divider from '@mui/material/Divider';
import FilterItem from './filterItem';
import Grid from '@mui/material/Grid2';
import InputList from '../InputList';
import Modal from '../Modals';
import SliderItem from './sliderItem';
import Typography from '@mui/material/Typography';
import { getUserId } from '../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';
import { preparePropVariables } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  button: {
    ...theme.palette.altButton,
  },
  editButton: {
    ...theme.palette.altButton,
    height: '28px',
    width: '50px',
    marginLeft: '5px',
  },
  apply: {
    ...theme.palette.button,
    height: '100%',
    width: '128px',
    marginRight: '10px',
  },
  buttonsContainer: {
    flex: '0 1 auto',
    padding: '10px 20px',
    borderTop: 'solid rgba(0, 0, 0, 0.12) thin',
  },
  filtersRoot: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'solid rgba(0, 0, 0, 0.12) thin',
    height: 'calc(100% - 100px)',
    overflow: 'auto',
    width: '100%',
    paddingTop: '10px',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 73.6px)',
    },
  },
  modal: {
    backgroundColor: 'white',
    height: '90vh',
    width: '90%',
    maxHeight: '1100px',
    maxWidth: '1400px',
  },
  chipItemContainer: {
    minHeight: '55px',
    padding: '10px 20px',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
    },
  },
  topFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 10px',
    maxHeight: '40%',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
    },
  },
  bottomFilters: {
    backgroundColor: '#F5F5F5',
    minHeight: '60%',
    padding: '0 10px',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
    },
  },
  checkListContainer: {
    height: '100%',
  },
  minMaxContainer: {
    ...theme.palette.altScrollbarBlue,
    overflow: 'auto',
    height: '100%',
    padding: '5px 15px 0',
    flexWrap: 'nowrap',
  },
  sliderContainer: {
    height: '100%',
    padding: '5px',
  },
  sliderScroll: {
    ...theme.palette.altScrollbarBlue,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 15px',
    overflow: 'auto',
    flexWrap: 'nowrap',
    width: '97%',
    height: 'calc(100% - 30.25px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 27.6px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 24.94px)',
    },
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  consolidateAssetsButton: {
    ...theme.palette.button,
    minWidth: '128px',
  },
  filterItem: {
    flexBasis: '20%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    paddingBottom: '5px',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      flexBasis: '33.33%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
}));

const BasicFiltersRow = ({
  context,
  filters,
  setFilters,
  filterItemClass,
  filterTypes = defaultFilterTypes,
  googleAnalyticsTag,
}) => {
  const classes = useStyles();
  const propVariables = preparePropVariables(filters);

  const deleteFilter = (filterToDelete) => {
    const newFilters = { ...filters };
    const { parent, value } = filterToDelete;
    newFilters[parent] = filters[parent].filter((val) => val.name !== value.name);
    setFilters(newFilters);
  };

  const onFilterChange = (filter, name) => {
    const newFilters = { ...filters };
    newFilters[name] = filter;
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.singleFilter,
      event_label: analytics({ [name]: newFilters[name] }),
      userId: getUserId(),
      ...dimensions(newFilters, context),
    });
    setFilters(newFilters);
  };

  return (
    <div className={classes.topFilters}>
      {filterTypes.map((filter) => (
        <FilterItem
          key={`${context}-modal-${filter.name}`}
          context={`${context}-modal`}
          filter={filter}
          setFilter={onFilterChange}
          propVariables={
            filter.propVariables && typeof filter.propVariables === 'function'
              ? filter.propVariables(filters, context)
              : propVariables
          }
          withChipList
          value={filters[filter.key]}
          deleteFilter={deleteFilter}
          disabled={filter.disabled ? filter.disabled(filters.assets) : false}
          controlValues={
            filter.control?.getControlValuesFromFilters
              ? filter.control.getControlValuesFromFilters(filters)
              : {}
          }
          inModal
          className={filterItemClass || classes.filterItem}
        />
      ))}
    </div>
  );
};

export const AdvancedFiltersRow = ({
  context,
  filters,
  setFilters,
  checkBoxFilterTypes = defaultCheckBoxFilterTypes,
  checkListFilterTypes = defaultCheckListFilterTypes,
  checkListItems,
  checkListAllReturnsNone = false,
  googleAnalyticsTag,
  minMaxFilterTypes = defaultMinMaxFilterTypes,
  sliderFiltersLabel = '',
  sliderFilterTypes = [],
  sliderFilterMaxValues = {},
  sliderFilterLoading,
  widths = { slider: { xs: 3 }, checkList: { xs: 8 }, minMax: { xs: 4 } },
  order = [
    AdvancedFilterCategories.sliders,
    AdvancedFilterCategories.checkLists,
    AdvancedFilterCategories.minMax,
  ],
  loading = false,
}) => {
  const classes = useStyles();
  const onFilterChange = (filter, name) => {
    const newFilters = { ...filters };
    newFilters[name] = filter;
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.singleFilter,
      event_label: analytics({ [name]: newFilters[name] }),
      userId: getUserId(),
      ...dimensions(newFilters, context),
    });
    setFilters(newFilters);
  };

  const sliderFilters = sliderFilterTypes.length > 0 && (
    <Grid className={classes.sliderContainer} container direction="column" size={widths.slider.xs}>
      {sliderFiltersLabel && (
        <Typography variant="overline" className={classes.subHeader} style={{ padding: '0 15px' }}>
          {sliderFiltersLabel}
        </Typography>
      )}
      <Grid className={classes.sliderScroll}>
        {sliderFilterTypes.map((filter) => (
          <SliderItem
            key={`${context}-modal-${filter.label}`}
            item={filter}
            loading={sliderFilterLoading}
            onChange={onFilterChange}
            value={filters[filter.key]}
            maxValue={sliderFilterMaxValues[filter.maxKey]}
          />
        ))}
      </Grid>
    </Grid>
  );

  const checkListFilters = checkListFilterTypes.length > 0 && (
    <Grid
      className={classes.checkListContainer}
      container
      direction="row"
      size={widths?.checkList?.xs}
      sx={{ justifyContent: 'space-evenly' }}
    >
      {checkListFilterTypes.map((filter) =>
        filter.group ? (
          <CheckListGroup
            key={filter.name}
            checkListItems={checkListItems}
            filter={filter}
            filters={filters}
            setFilter={onFilterChange}
          />
        ) : (
          <CheckListItem
            key={filter.name}
            checkListItems={checkListItems}
            filter={filter}
            filters={filters}
            setFilter={onFilterChange}
            allReturnsNone={checkListAllReturnsNone}
          />
        ),
      )}
    </Grid>
  );

  const minMaxFilters = (minMaxFilterTypes.length > 0 || checkBoxFilterTypes.length > 0) && (
    <Grid className={classes.minMaxContainer} container direction="column" size={widths.minMax.xs}>
      <InputList values={filters} setInput={onFilterChange} items={minMaxFilterTypes} />
      <Divider style={{ marginTop: '10px' }} />
      {checkBoxFilterTypes.map((filter) => (
        <CheckBoxItem
          key={`${context}-modal-${filter.label}`}
          item={filter}
          onClick={onFilterChange}
          value={filters[filter.key]}
        />
      ))}
    </Grid>
  );

  const filterComponents = {
    [AdvancedFilterCategories.sliders]: sliderFilters,
    [AdvancedFilterCategories.checkLists]: checkListFilters,
    [AdvancedFilterCategories.minMax]: minMaxFilters,
  };

  if (loading) {
    return (
      <Grid className={classes.bottomFilters} container direction="row" size={12}>
        <Typography variant="h6" align="center">
          Loading filters...
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid className={classes.bottomFilters} container direction="row" size={12}>
      {order.map((filter) => filterComponents[filter])}
    </Grid>
  );
};

export default ({
  apply,
  checkBoxFilterTypes = defaultCheckBoxFilterTypes,
  checkListFilterTypes = defaultCheckListFilterTypes,
  checkListItems,
  clearFilters,
  context,
  defaultFilters,
  filterItemClass,
  filterTypes = defaultFilterTypes,
  googleAnalyticsTag,
  minMaxFilterTypes = defaultMinMaxFilterTypes,
  open,
  setOpen,
  sliderFiltersLabel = '',
  sliderFilterTypes = [],
  sliderFilterMaxValues = {},
  sliderFilterLoading,
  title,
  widths = { slider: { xs: 3 }, checkList: { xs: 8 }, minMax: { xs: 4 } },
}) => {
  const classes = useStyles();
  const [filters, setFilters] = useState(defaultFilters);

  const onApply = () => {
    apply(filters);
    setOpen(false);
  };

  useEffect(() => setFilters(defaultFilters), [defaultFilters]);

  return (
    <Modal
      handleClose={() => setOpen(false)}
      open={open}
      title={title}
      titleAlignedWithClose={true}
      styles={classes}
    >
      <Grid container className={classes.filtersRoot} direction="column">
        <Divider />
        <div className={classes.filtersContainer}>
          <BasicFiltersRow
            context={context}
            filters={filters}
            setFilters={setFilters}
            filterItemClass={filterItemClass}
            filterTypes={filterTypes}
            googleAnalyticsTag={googleAnalyticsTag}
          />

          <AdvancedFiltersRow
            context={context}
            filters={filters}
            setFilters={setFilters}
            checkBoxFilterTypes={checkBoxFilterTypes}
            checkListFilterTypes={checkListFilterTypes}
            checkListItems={checkListItems}
            googleAnalyticsTag={googleAnalyticsTag}
            minMaxFilterTypes={minMaxFilterTypes}
            sliderFiltersLabel={sliderFiltersLabel}
            sliderFilterTypes={sliderFilterTypes}
            sliderFilterMaxValues={sliderFilterMaxValues}
            sliderFilterLoading={sliderFilterLoading}
            widths={widths}
          />
        </div>
      </Grid>

      <Grid container className={classes.buttonsContainer} size={12}>
        <Grid size={{ xs: 2, md: 3 }} />
        <Grid container size={{ xs: 8, md: 6 }} sx={{ justifyContent: 'center' }}>
          <Button variant="contained" className={classes.apply} onClick={onApply}>
            Apply
          </Button>
        </Grid>
        <Grid container size={{ xs: 2, md: 3 }} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" className={classes.button} onClick={clearFilters}>
            Clear
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
