import { formatDateShort, formatPrimaryHydrocarbon } from '../../../utils/helpers';

export const CNH_WELL_SUMMARY_ITEM = {
  label: 'CNH Data Available',
  accessor: 'cnhDataAvailability',
};
export const DATA_WELL_SUMMARY_ITEM = {
  label: 'Well Data Available',
  accessor: 'Contact Geoex for access at jp.rossi@geoexltd.com',
};
export const NAME_WELL_SUMMARY_ITEM = {
  label: 'Well Name',
  accessor: 'displayName',
};

export const BLOCK_WELL_SUMMARY_ITEM = {
  label: 'Block',
  accessor: (w) => w?.block?.displayName,
};

export const FIELD_WELL_SUMMARY_ITEM = {
  label: 'Field',
  accessor: (w) => (w?.field ? w?.field.displayName : ''),
};

export const PROSPECT_WELL_SUMMARY_ITEM = {
  label: 'Prospect',
  accessor: 'prospect',
};

export const BASIN_WELL_SUMMARY_ITEM = {
  label: 'Basin',
  accessor: (w) => w?.basin?.name,
};

export const SHORE_STATUS_WELL_SUMMARY_ITEM = {
  label: 'Shore Status',
  accessor: (w) => w?.shoreStatus?.name,
};

export const WEA_WELL_SUMMARY_ITEM = {
  label: 'Type',
  accessor: 'weaClassification',
};

export const RESULT_WELL_SUMMARY_ITEM = {
  label: 'Result',
  accessor: 'wellResult',
};

export const DESIGN_WELL_SUMMARY_ITEM = {
  label: 'Design',
  accessor: (w) => w?.wellDesign?.name,
};

export const STATUS_WELL_SUMMARY_ITEM = {
  label: 'Status',
  accessor: 'wellStatus',
};

export const HYDROCARBON_WELL_SUMMARY_ITEM = {
  label: (w) => (w?.country?.isoCode === 'ARG' ? 'Resource Theme:' : 'Primary Hydrocarbon'),
  accessor: (w) => formatPrimaryHydrocarbon(w?.primaryHydrocarbon),
};

export const FORMATION_WELL_SUMMARY_ITEM = {
  label: 'Formation',
  accessor: 'wellFormation',
};

export const VERTICAL_DEPTH_WELL_SUMMARY_ITEM = {
  label: 'True Vertical Depth',
  unit: '(m)',
  accessor: (w) => (w?.depthVertical > 0 ? w?.formattedDepthVertical : null),
};

export const MEASURED_DEPTH_WELL_SUMMARY_ITEM = {
  label: 'True Measured Depth',
  unit: '(m)',
  accessor: (w) => (w?.depthMeasure > 0 ? w?.formattedDepthMeasure : null),
  exceptions: true,
};

export const WATER_DEPTH_WELL_SUMMARY_ITEM = {
  label: 'Water Depth',
  accessor: (w) => (w?.waterBlade > 0 ? w?.formattedWaterBlade : null),
  exceptions: true,
};

export const DRILL_START_WELL_SUMMARY_ITEM = {
  label: 'Drill Start',
  accessor: (w) => formatDateShort(w?.drillingStartDate),
};

export const DRILL_END_WELL_SUMMARY_ITEM = {
  label: 'Drill End',
  accessor: (w) => formatDateShort(w?.drillingEndDate),
};

export const DRILL_DAYS_WELL_SUMMARY_ITEM = {
  label: 'Drill Days',
  accessor: (w) => (w?.drillDays > 0 ? w?.drillDays : null),
};

export const DRILL_YEAR_WELL_SUMMARY_ITEM = {
  label: 'Drill Year',
  accessor: (w) =>
    !w?.drillingStartDate && !w?.drillingEndDate && !!w?.drillingYear ? w?.drillingYear : null,
};

export const RIG_NAME_WELL_SUMMARY_ITEM = {
  label: 'Rig Name',
  accessor: 'drillingRig',
};

export const LATERAL_LENGTH_WELL_SUMMARY_ITEM = {
  label: 'Lateral Length',
  unit: '(m)',
  accessor: (w) =>
    w?.formattedFracStageLateralLengthM && parseFloat(w?.formattedFracStageLateralLengthM) > 0
      ? w?.formattedFracStageLateralLengthM
      : null,
};

export const FRAC_STAGES_WELL_SUMMARY_ITEM = {
  label: '# Frac Stages',
  accessor: (w) => (w?.fracStages.length > 0 ? w?.fracStages.length : null),
};

export const COMPLETION_TYPE_WELL_SUMMARY_ITEM = {
  label: 'Completion Type',
  accessor: 'mostRecentFracStageCompletionType',
};

export const SAND_PUMPED_WELL_SUMMARY_ITEM = {
  label: 'Sand Pumped',
  unit: '(te)',
  accessor: (w) =>
    w?.formattedFracStageSandPumped && parseFloat(w?.formattedFracStageSandPumped) > 0
      ? w?.formattedFracStageSandPumped
      : null,
};

export const WATER_INJECTED_WELL_SUMMARY_ITEM = {
  label: 'Water Injected',
  unit: '(bbl)',
  accessor: (w) =>
    w?.formattedFracStageWaterInjected && parseFloat(w?.formattedFracStageWaterInjected) > 0
      ? w?.formattedFracStageWaterInjected
      : null,
};

export const PRODUCTION_FACILITY_WELL_SUMMARY_ITEM = {
  label: 'Production Facility',
  accessor: (w) => w?.facility?.name,
};

export const IP_OIL_WELL_SUMMARY_ITEM = {
  label: 'IP',
  unit: '(Oil) (bbl/d)',
  accessor: (w) => (w?.ipLiquidsBbld > 0 ? w?.formattedIpLiquidsBbld : null),
};

export const IP_GAS_WELL_SUMMARY_ITEM = {
  label: 'IP',
  unit: '(Gas) (MMcf/d)',
  accessor: (w) => (w?.ipGasMmcfd > 0 ? w?.formattedIpGasMmcfd : null),
};

export const EUR_OIL_WELL_SUMMARY_ITEM = {
  label: 'EUR',
  unit: '(Oil) (MMbbl)',
  accessor: (w) => (w?.eurLiquidsMmbbl > 0 ? w?.formattedEurLiquidsMmbbl : null),
};

export const EUR_GAS_WELL_SUMMARY_ITEM = {
  label: 'EUR',
  unit: '(Gas) (Bcf)',
  accessor: (w) => (w?.eurGasBcf > 0 ? w?.formattedEurGasBcf : null),
};

export const GOR_WELL_SUMMARY_ITEM = {
  label: 'Recent GOR',
  unit: '(scf/bbl)',
  accessor: (w) => (w?.gorScfBbl > 0 ? w?.formattedGorScfBbl : null),
};

export const WATER_CUT_WELL_SUMMARY_ITEM = {
  label: 'Recent Water Cut',
  unit: '(%)',
  accessor: (w) => (w?.recent3MonthWaterCutPct > 0 ? w?.formattedRecent3MonthWaterCutPct : null),
};

export const LIQUID_PRODUCTION_WELL_SUMMARY_ITEM = {
  label: 'Recent Liquids Production',
  unit: (w) =>
    w?.recentProductionDate ? `(${formatDateShort(w?.recentProductionDate)}) (bbl/d)` : null,
  accessor: (w) =>
    w?.recentProductionLiquidsBbld > 0 ? w?.formattedRecentProductionLiquidsBbld : null,
  exceptions: true,
};

export const GAS_PRODUCTION_WELL_SUMMARY_ITEM = {
  label: 'Recent Gas Production',
  unit: (w) =>
    w?.recentProductionDate ? `(${formatDateShort(w?.recentProductionDate)}) (MMcf/d)` : null,
  accessor: (w) => (w?.recentProductionGasMmcfd > 0 ? w?.formattedRecentProductionGasMmcfd : null),
  exceptions: true,
};

export const DISCOVERY_WELL_SUMMARY_ITEM = {
  label: 'Discovery Well',
  accessor: (w) => {
    if (w?.discoveryWell === null) return null;
    return (w?.discoveryWell ? "Yes" : "No");
  },
};