import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import React from 'react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  alignedHeader: {
    ...theme.palette.accentText,
    fontWeight: 'bold',
    padding: '9px 20px',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 20px',
    },
  },
  close: {
    ...theme.modalButton,
  },
  header: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '10px',
  },
  modalRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      minHeight: '36px',
    },
  },
  modal: {
    height: '430px',
    width: '875px',
  },
  root: {
    ...theme.modal,
  },
}));

// children - children components
// disablePortal - keeps child components within the parent (added specifically for the case of mounted child modals nested
//  within modals mounted afterwards)
// handleClose - function to close the modal
// keepMounted - flag to keep the modal mounted (mounted in DOM even when closed)
// open - flag for opening the modal
// styles - styles to overwrite components
// title - title for modal
// titleAlignedWithClose - Aligns the title with the close button (see analytics mock)

export default ({
  children,
  disablePortal,
  handleClose,
  keepMounted,
  open,
  styles,
  title,
  titleAlignedWithClose,
}) => {
  const classes = useStyles();

  const header = titleAlignedWithClose ? (
    <div className={clsx(classes.modalRow, 'modal-header')}>
      {title ? (
        <Typography className={classes.alignedHeader} variant="overline">
          {title}
        </Typography>
      ) : null}
      <IconButton
        onClick={handleClose}
        className={styles && styles.close ? clsx(classes.close, styles.close) : classes.close}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </div>
  ) : (
    <>
      <IconButton
        onClick={handleClose}
        className={styles && styles.close ? clsx(classes.close, styles.close) : classes.close}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <Typography className={classes.header} variant="h4">
        {title}
      </Typography>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      keepMounted={keepMounted}
      disablePortal={disablePortal}
    >
      <div
        className={
          styles && styles.modal
            ? clsx(classes.root, classes.modal, styles.modal)
            : clsx(classes.root, classes.modal)
        }
      >
        {header}
        {children}
      </div>
    </Modal>
  );
};
