import { INITIAL_YEARS, useFilters } from '../../../FiltersContext';
import { roundDownToNearestThousand, roundUpToNearestThousand } from '../utils';

import { MaFilterKeys } from '../types';
import { isNumber } from '../../../../../utils/helpers';
import { useEffect } from 'react';
import { useGetMaFiltersSummary } from './useGetMaFiltersSummary';

/**
 * Hook to update the FiltersContext with the available slider ranges based on the data returned from the GraphQL query.
 * This is needed to allow us to reset the slider values when the user clears the filters.
 *
 * @returns
 */
export const useUpdateFilterRanges = () => {
  const { data, loading } = useGetMaFiltersSummary();
  const {
    setFilters,
    setYearsRange,
    setValuationRange,
    setReservesRange,
    setProductionRange,
    setEmissionsRange,
  } = useFilters();

  useEffect(() => {
    if (loading || !data) return;

    if (isNumber(data?.startYear) && isNumber(data?.endYear)) {
      setYearsRange([data.startYear, data.endYear]);
      // It was requested that the start year should be 2010 by default, so just ensure that's within the range
      const minYear = Math.max(data.startYear, INITIAL_YEARS[0]);
      // Set filters to the updated range
      setFilters((prevFilters) => ({
        ...prevFilters,
        [MaFilterKeys.dates]: [minYear, data.endYear],
      }));
    }

    if (isNumber(data?.minValuation) && isNumber(data?.maxValuation)) {
      const valuationRange = [
        roundDownToNearestThousand(data.minValuation),
        roundUpToNearestThousand(data.maxValuation),
      ];
      setValuationRange(valuationRange);
      setFilters((prevFilters) => ({ ...prevFilters, [MaFilterKeys.valuation]: valuationRange }));
    }

    if (isNumber(data?.minReserves) && isNumber(data?.maxReserves)) {
      const reservesRange = [
        roundDownToNearestThousand(data.minReserves),
        roundUpToNearestThousand(data.maxReserves),
      ];
      setReservesRange(reservesRange);
      setFilters((prevFilters) => ({ ...prevFilters, [MaFilterKeys.reserves]: reservesRange }));
    }

    if (isNumber(data?.minProduction) && isNumber(data?.maxProduction)) {
      const productionRange = [
        roundDownToNearestThousand(data.minProduction),
        roundUpToNearestThousand(data.maxProduction),
      ];
      setProductionRange(productionRange);
      setFilters((prevFilters) => ({ ...prevFilters, [MaFilterKeys.production]: productionRange }));
    }

    if (isNumber(data?.minEmissions) && isNumber(data?.maxEmissions)) {
      const emissionsRange = [
        roundDownToNearestThousand(data.minEmissions),
        roundUpToNearestThousand(data.maxEmissions),
      ];
      setEmissionsRange(emissionsRange);
      setFilters((prevFilters) => ({ ...prevFilters, [MaFilterKeys.emissions]: emissionsRange }));
    }
  }, [data, loading]);

  return null;
};
