import { useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { SEARCH_REPORTS_RESULTS, SEARCH_RESULTS } from '../operations';
import { useDebounce } from '../../../utils/useDebounce';
import { storeGlobalSearchHistory } from '../GlobalSearch.controller';
import { GLOBAL_SEARCH_EVENTS, GLOBAL_SEARCH_GOOGLE_ANALYTICS_TAG } from '../constants';
import { getUserId } from '../../../utils/auth';
import { getGoogleAnalyticsDimentions } from '../helper';
import { gtag } from '../../../utils/gtag';

export const useSearchModalController = ({ inputText, setHistory }) => {
  const inputTextDebounced = useDebounce(inputText, 500);
  const [clickCounter, setClickCounter] = useState(0);
  const [reportClickCounter, setReportClickCounter] = useState(0);

  const clearCounters = useCallback(() => {
    setClickCounter(0);
    setReportClickCounter(0);
  }, []);

  const { loading, data } = useQuery(SEARCH_RESULTS, {
    onCompleted: clearCounters,
    variables: {
      query: inputTextDebounced,
      filter: {
        labelGroups: [],
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    keepPreviousData: true,
    staleTime: 500,
  });

  const { loading: loadingReports, data: dataReports } = useQuery(SEARCH_REPORTS_RESULTS, {
    onCompleted: clearCounters,
    variables: {
      query: inputTextDebounced,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    keepPreviousData: true,
    staleTime: 500,
  });

  const onActionClick = useCallback(
    ({ isReport, row, download }) => {
      let reportCounter = reportClickCounter;
      let counter = clickCounter;
      if (isReport) {
        reportCounter = reportClickCounter + 1;
        setReportClickCounter(reportCounter);
      } else {
        counter = clickCounter + 1;
        setClickCounter(counter);
      }

      const gaDimentions = getGoogleAnalyticsDimentions(row, counter, reportCounter);
      gtag('event', GLOBAL_SEARCH_EVENTS.event, {
        event_category: GLOBAL_SEARCH_GOOGLE_ANALYTICS_TAG,
        event_action: GLOBAL_SEARCH_EVENTS.actionClick,
        event_label: download ? 'search_result_download' : 'search_result_click',
        userId: getUserId(),
        ...gaDimentions,
      });

      const newHistory = storeGlobalSearchHistory(inputText);
      newHistory && setHistory(newHistory);
    },
    [inputText, setHistory, clickCounter, reportClickCounter],
  );

  return {
    loading,
    data,
    loadingReports,
    dataReports: dataReports?.fulltextReportsSearch || [],
    onActionClick,
  };
};
