import { formatDateShort } from '../../../utils/helpers';

// Shared by asset, block, field, restricted area
export const ACREAGE_SUMMARY_ITEM = {
  label: 'Area',
  unit: '(km²)',
  accessor: 'formattedAcreage',
};

export const ASSETS_SUMMARY_ITEM = {
  label: 'Assets',
  accessor: (entity) => entity?.assets?.map((a) => a.displayName).join(', '),
};

// Shared by facility, pipeline segment
export const ASSOCIATED_ASSETS_SUMMARY_ITEM = {
  label: 'Associated Assets',
  accessor: (entity) => entity?.assets?.map((a) => a.displayName).join(', '),
};

export const ASSOCIATED_BLOCKS_SUMMARY_ITEM = {
  label: 'Blocks',
  accessor: (entity) => entity?.blocks?.map((b) => b.displayName).join(', '),
};

// Shared by asset, block, restricted area
export const AVERAGE_VERTICAL_WELL_DEPTH_SUMMARY_ITEM = {
  label: 'Average Vertical Well Depth',
  accessor: (entity) => (entity?.verticalDepthM > 0 ? entity?.formattedVerticalDepth : null),
};

// Shared by asset, basin, block, ea well
export const BASIN_SUMMARY_ITEM = {
  label: 'Basin',
  accessor: (entity) => entity?.basin?.name || entity?.basinName,
};

// Shared by block, restricted area
export const BLOCK_DISPLAY_NAME_SUMMARY_ITEM = {
  label: 'Block',
  accessor: (entity) => entity?.displayName || entity?.name,
};

// Shared by asset, block
export const CONTRACT_END_SUMMARY_ITEM = {
  label: 'Contract End',
  accessor: (entity) => formatDateShort(entity?.licenseExpirationDate),
};

// Shared by asset, block
export const CONTRACT_START_SUMMARY_ITEM = {
  label: 'Contract Start',
  accessor: (entity) => formatDateShort(entity?.licenseAwardDate),
};

export const COUNTRY_NAME_SUMMARY_ITEM = {
  label: 'Country',
  accessor: 'countryName',
};

export const COUNTRY_SUMMARY_ITEM = {
  label: 'Country / Area',
  accessor: (entity) => entity?.country?.displayName,
};

// Shared by pipeline, pipeline segment
export const DESTINATION_SUMMARY_ITEM = {
  label: 'Destination',
  accessor: 'destination',
};

export const DEVELOPMENT_STATUS_SUMMARY_ITEM = {
  label: 'Development Status',
  accessor: (entity) => entity?.developmentStatus?.name,
};

export const DEVELOPMENT_TYPE_SUMMARY_ITEM = {
  label: 'Development Type',
  accessor: (entity) => entity?.developmentType?.name,
};

// Shared by pipeline, pipeline segment
export const DIAMETER_SUMMARY_ITEM = {
  label: 'Diameter',
  unit: '(inches)',
  accessor: 'formattedDiameterInches',
};

// Shared by asset, field
export const DISCOVERY_DATE_SUMMARY_ITEM = {
  label: 'Discovery Date',
  accessor: (entity) => formatDateShort(entity?.discoveryDate),
};

// Shared by asset, block, restricted area
export const FISCAL_REGIME_SUMMARY_ITEM = {
  label: 'Fiscal Regime',
  accessor: (entity) => entity?.fiscalRegime?.name || entity?.fiscalRegime,
};

// Shared by ccus project, storage site
export const FORMATTED_PARTNERS_SUMMARY_ITEM = {
  label: 'Partners',
  accessor: 'formattedPartners',
};

// Shared by co2 emitter, ccus project
export const INDUSTRY_SUMMARY_ITEM = {
  label: 'Industry Type',
  accessor: 'industryType',
};

// Shared by pipeline, pipeline network, well
export const OPERATOR_SUMMARY_ITEM = {
  label: 'Operator',
  accessor: 'operator',
};

// Shared by pipeline, pipeline segment
export const ORIGIN_SUMMARY_ITEM = {
  label: 'Origin',
  accessor: 'origin',
};

const EVENT_TYPES_WITH_NO_COMPANY = [
  'Relinquishment',
  'Bankruptcy/Failure to Meet Obligations',
  'License End',
];

// Differs from partners as it INCLUDES the operator
// Shared by asset, block, ea well, field
export const PARTICIPATION_SUMMARY_ITEM = {
  label: 'Participation',
  accessor: (entity) => {
    let displayedParticipations = [...(entity?.participations || entity?.flexParticipations || [])];
    if (displayedParticipations.length === 0) return null;

    if (displayedParticipations[0].effectiveDate) {
      const currDate = displayedParticipations[0].effectiveDate;
      displayedParticipations = displayedParticipations.filter((p) => p.effectiveDate === currDate);
    }

    return displayedParticipations
      .sort((a, b) =>
        a.isCompanyOperator ? -1 : b.isCompanyOperator ? 1 : b.percentStake - a.percentStake,
      )
      .map(
        ({
          company,
          companyName,
          effectiveDate,
          eventType,
          formattedPercentStake,
          isCompanyOperator,
        }) => {
          const name = company ? company.name : companyName || '';
          return isCompanyOperator
            ? `${name} (Operator) (${formattedPercentStake})`
            : EVENT_TYPES_WITH_NO_COMPANY.includes(eventType)
            ? `${eventType} (${formatDateShort(effectiveDate)})`
            : `${name} (${formattedPercentStake})`;
        },
      )
      .join(', ');
  },
};

export const RESOURCE_THEME_SUMMARY_ITEM = {
  label: 'Resource Theme',
  accessor: (entity) => entity?.resourceTheme?.name,
};

// Shared by asset, block, ea well, restricted area
export const SHORE_STATUS_SUMMARY_ITEM = {
  label: 'Shore Status',
  accessor: (entity) => entity?.shoreStatus?.name || entity?.formattedShoreStatus,
};

// Shared by ccus project, storage site
export const STORAGE_TYPE_SUMMARY_ITEM = {
  label: 'Storage Type',
  accessor: 'storageType',
};

// Shared by asset, block, facility, field, lease
export const WATER_DEPTH_M_SUMMARY_ITEM = {
  label: 'Water Depth',
  accessor: (entity) => {
    const { formattedWaterDepth, formattedWaterDepthM, waterDepth, waterDepthM } = entity;
    const depth = waterDepth || waterDepthM;
    const formattedDepth = formattedWaterDepth || formattedWaterDepthM || depth;
    return depth > 0 ? formattedDepth : null;
  },
};

// Shared by asset, block
export const X_FACTOR_SUMMARY_ITEM = {
  label: 'X Factor',
  unit: '(%)',
  accessor: (entity) => entity?.formattedXFactor,
};
