import React, { useEffect, useRef, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useCustomOptionsStyles } from './CustomOptions.style';

export const CustomOptions = ({
  setBottomOffset,
  control,
  controlClass,
  context,
  note,
  dataFromQuery,
}) => {
  const classes = useCustomOptionsStyles();

  const customOptionsRef = useRef();
  useEffect(() => {
    if (customOptionsRef?.current) {
      setBottomOffset(customOptionsRef.current.getBoundingClientRect()?.height || 0);
    }
  }, [customOptionsRef?.current]);

  const onClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div ref={customOptionsRef} className={classes.customOptionWrapper}>
      {control.controlItems && control.controlValues && (
        <FormControl onClick={onClick} component="fieldset" className={controlClass}>
          {control.controlItems.map((c) => (
            <RadioGroup
              key={`autoCompleteWithControl-control-item-${context}-${c.name}`}
              aria-label={c.name}
              value={control.controlValues[c.key]}
              onClick={() => control.onChange(!control.controlValues[c.key], c.key)}
            >
              <FormControlLabel value={true} control={<Radio size="small" />} label={c.label} />
            </RadioGroup>
          ))}
        </FormControl>
      )}
      {note && (
        <Typography
          onClick={onClick}
          className={clsx(classes.noteLabel, {
            [classes.noteLabelHidden]: note.hideCallback && note.hideCallback(dataFromQuery),
          })}
        >
          {note.label}
        </Typography>
      )}
    </div>
  );
};
