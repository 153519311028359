import {
  TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM,
  TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS,
  TRANSACTIONS_STACK_BY_TYPE_ENUM,
} from '../types';

import { MA_TRANSACTIONS_AGGREGATES_STACKED_QUERY } from '../operations';
import { useFilters } from '../../../FiltersContext';
import { useQuery } from '@apollo/client';

export const useGetMaTransactionsAggregatesStacked = (
  by = TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
  stackBy = TRANSACTIONS_STACK_BY_TYPE_ENUM.BUYER,
  fields = TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS,
  skip = true,
) => {
  const { filterVariables } = useFilters();
  const { data, loading, error } = useQuery(MA_TRANSACTIONS_AGGREGATES_STACKED_QUERY, {
    variables: { by, stackBy, fields, filters: filterVariables },
    skip,
  });

  return {
    data: data?.maTransactionsAggregatesStacked?.data || [],
    stacks: data?.maTransactionsAggregatesStacked?.stacks || [],
    loading,
    error,
  };
};
