import { gql } from '@apollo/client';

export const MA_ASSETS_BY_NAME_WITH_FILTER_QUERY = gql`
  query maAssetsByNameWithFilter($search: String, $filters: MaFilters) {
    maAssetsByNameWithFilter(search: $search, filters: $filters) {
      assets {
        id
        legacyId
        name
        displayName
        entityId
        entityType
      }
      totalCount
    }
  }
`;

export const MA_EVENT_TYPES_QUERY = gql`
  {
    maDealTypes {
      id
      name
      group
    }
  }
`;
