import {
  AdvancedFilterCategories,
  DEVELOPMENT_STATUSES_CHECK_LIST,
  HYDROCARBONS_CHECK_LIST,
  SHORE_STATUSES_CHECK_LIST,
} from '../../../Filters/filterTypes';
import { BASIC_FILTER_TYPES, GOOGLE_ANALYTICS_TAG, MaFilterKeys, MaFilterLabels } from './types';
import { Box, Button, Collapse, Divider, Skeleton, Typography } from '@mui/material';
import { MA_CONTEXT, useFilters } from '../../FiltersContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AdvancedFiltersRow } from '../../../Filters/advancedFilters';
import BasicFilters from '../../../Filters/basicFilters';
import { FilterList } from '@mui/icons-material';
import { HYDROCARBON_TYPES } from '../../../../utils/constants';
import { Slider } from '../../../basic/Slider';
import { getMarks } from './utils';
import { useGetDevelopmentStatuses } from './hooks/useGetDevelopmentStatuses';
import { useGetMaFiltersSummary } from './hooks/useGetMaFiltersSummary';
import { useGetShoreStatuses } from './hooks/useGetShoreStatuses';
import { useGetSliderFilterTypes } from './hooks/useGetSliderFilterTypes';
import { useStyles } from './styles';
import { useUpdateFilterRanges } from './hooks/useUpdateFilterRanges';

const SliderSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.slider}>
      <Box sx={{ marginTop: 1 }}>
        <Skeleton variant="rectangular" width={200} height={13} />
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <Skeleton variant="rectangular" width="100%" height={10} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
        <Skeleton variant="rectangular" width={50} height={10} />
        <Skeleton variant="rectangular" width={50} height={10} />
      </Box>
    </div>
  );
};

export const Filters = () => {
  const classes = useStyles();
  const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false);
  const { loading, filters, setFilters, setRefresh } = useFilters();
  const { shoreStatuses } = useGetShoreStatuses();
  const { developmentStatuses } = useGetDevelopmentStatuses();
  const { data, loading: loadingSummary } = useGetMaFiltersSummary();
  const { sliderFilters, sliderMaxValues } = useGetSliderFilterTypes();

  const marks = useMemo(() => getMarks(data?.startYear, data?.endYear, true), [data]);

  useUpdateFilterRanges();

  const handleChangeValueSlider = useCallback(
    (_, newValue) => {
      setFilters({ ...filters, [MaFilterKeys.dates]: newValue });
    },
    [filters],
  );

  const toggleAdvancedFilters = () => {
    // force re-render of the graph because of height change
    // using setTimeout to avoid flickering
    setTimeout(() => setRefresh(true), 400);
    setOpenAdvancedFilters((prev) => !prev);
  };

  useEffect(() => {
    // force re-render of the graph because of height change
    // using setTimeout to avoid flickering
    setTimeout(() => setRefresh(false), 450);
  }, [openAdvancedFilters]);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" flexGrow={1} id="basic-filters-row">
        <div className={classes.sliderWrapper}>
          {/* Using a Skeleton when loading to force a re-render of
              the Slider component to avoid bug with overlapping dates */}
          {loading || loadingSummary ? (
            <SliderSkeleton />
          ) : (
            <Slider
              className={classes.slider}
              classNameLabel={classes.sliderLabel}
              value={filters[MaFilterKeys.dates]}
              onChange={handleChangeValueSlider}
              label={MaFilterLabels.dates}
              getAriaLabel={() => MaFilterLabels.dates}
              marks={marks}
              min={marks.length ? marks[0].value : undefined}
              max={marks.length ? marks[marks.length - 1].value : undefined}
              step={1}
            />
          )}
        </div>

        <BasicFilters
          applyFilters={setFilters}
          context={MA_CONTEXT}
          filters={filters}
          googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
          basicFilterTypes={BASIC_FILTER_TYPES}
          filterItemClass={classes.filterItem}
        />

        <Button
          startIcon={<FilterList />}
          id="ma-advanced-filters"
          className={classes.allFiltersButton}
          variant="contained"
          color="secondary"
          onClick={toggleAdvancedFilters}
          disabled={loading || loadingSummary}
        >
          {openAdvancedFilters ? 'Less Filters' : 'All Filters'}
        </Button>
      </Box>

      <Collapse
        id="advanced-filters-panel"
        in={openAdvancedFilters}
        timeout="auto"
        unmountOnExit
        sx={{ marginLeft: '1rem' }}
      >
        <Divider sx={{ margin: '5px 20px' }}>
          <Typography variant="overline">Advanced Filters</Typography>
        </Divider>
        <AdvancedFiltersRow
          loading={loading || loadingSummary}
          context={MA_CONTEXT}
          filters={filters}
          setFilters={setFilters}
          googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
          minMaxFilterTypes={[]}
          sliderFiltersLabel="Transaction Values"
          sliderFilterTypes={sliderFilters}
          sliderFilterMaxValues={sliderMaxValues}
          checkListAllReturnsNone
          checkListFilterTypes={[
            HYDROCARBONS_CHECK_LIST,
            DEVELOPMENT_STATUSES_CHECK_LIST,
            SHORE_STATUSES_CHECK_LIST,
          ]}
          checkListItems={{
            shoreStatuses,
            developmentStatuses,
            hydrocarbons: HYDROCARBON_TYPES,
          }}
          order={[AdvancedFilterCategories.checkLists, AdvancedFilterCategories.sliders]}
          widths={{ slider: { xs: 4 }, checkList: { xs: 8 } }}
        />
      </Collapse>
    </Box>
  );
};
