import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GLOBAL_ASSUMPTIONS_QUERY } from './operations';
import ConsolidateAssetsModalOilGas from './custom';

export default ({
  companyIds,
  assetUnits,
  discountRate,
  open,
  setDiscountRate,
  setOpen,
  consolidatedAssetsData: { setRequestingReport, requestReport, assetIds },
}) => {
  const [baseYear, setBaseYear] = useState(new Date().getFullYear());
  const [basePricesGlobalAssumptions, setBasePricesGlobalAssumptions] = useState({
    oil: ['', '', '', '', ''],
    escalatorOil: '',
    gas: ['', '', '', '', ''],
    escalatorGas: '',
  });

  const { data: globalAssumptionsData } = useQuery(GLOBAL_ASSUMPTIONS_QUERY);

  const calculateBasePrices = (base, year1, year2, year3, year4, escalation) => {
    const factor = 1 + escalation / 100;
    let prices = [base, year1, year2, year3, year4].map((price) => {
      return price !== '' ? parseFloat(price) : '';
    });

    for (let i = 1; i < prices.length; i++) {
      if (prices[i] === '') {
        prices[i] = prices[i - 1] !== '' ? prices[i - 1] * factor : '';
      }
    }

    return prices.map((price) => String(price));
  };

  useEffect(() => {
    if (globalAssumptionsData?.globalAssumptions?.baseYear) {
      const baseYear = parseFloat(globalAssumptionsData.globalAssumptions['baseYear']);
      const brentPriceBaseYear = parseFloat(
        globalAssumptionsData.globalAssumptions['brentPriceBaseYear'],
      );
      const brentPriceBaseYearPlusOne = parseFloat(
        globalAssumptionsData.globalAssumptions['brentPriceBaseYearPlusOne'],
      );
      const brentPriceBaseYearPlusTwo = parseFloat(
        globalAssumptionsData.globalAssumptions['brentPriceBaseYearPlusTwo'],
      );
      const costInflation = parseFloat(globalAssumptionsData.globalAssumptions['costInflation']);
      const oilPrices = calculateBasePrices(
        brentPriceBaseYear,
        brentPriceBaseYearPlusOne,
        brentPriceBaseYearPlusTwo,
        '',
        '',
        costInflation,
      );

      const basePrices = {
        oil: oilPrices,
        escalatorOil: costInflation,
        gas: ['', '', '', '', ''],
        escalatorGas: '',
      };

      setBaseYear(baseYear);
      setBasePricesGlobalAssumptions(basePrices);
    }
  }, [globalAssumptionsData]);

  return (
    <>
      <ConsolidateAssetsModalOilGas
        assetIds={assetIds}
        companyIds={companyIds}
        assetUnits={assetUnits}
        open={open}
        setOpen={setOpen}
        discountRate={discountRate}
        requestReport={requestReport}
        setDiscountRate={setDiscountRate}
        setRequestingReport={setRequestingReport}
        baseYear={baseYear}
        basePricesGlobalAssumptions={basePricesGlobalAssumptions}
        calculateBasePrices={calculateBasePrices}
      />
    </>
  );
};
