import { gql } from '@apollo/client';

export const MA_DEALS_QUERY = gql`
  query maDeals($filters: MaFilters) {
    maDeals(filters: $filters) {
      effectiveDate
      acquisitionCostSum
      priceFlowingBarrelSum

      maCompanyTransactions {
        buyerCompany {
          name
        }

        sellerCompany {
          name
        }

        soldCompany {
          name
        }

        maAssetTransactions {
          assetName
        }
      }
    }
  }
`;
