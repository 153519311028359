import { gql } from '@apollo/client';

export const MA_TRANSACTIONS_SUMMARY_QUERY = gql`
  query maTransactionsSummary($filters: MaFilters) {
    maTransactionsSummary(filters: $filters) {
      numDeals
      medianValuation
      medianReserves
      medianReservesAcquisitionCost
      medianProductionAcquisitionCost
    }
  }
`;

export const MA_TRANSACTIONS_AGGREGATES_QUERY = gql`
  query maTransactionsAggregates(
    $by: MaAggregateByEnum!
    $filters: MaFilters
    $valuationsSum: Boolean!
    $reservesSum: Boolean!
    $productionsSum: Boolean!
    $acquisitionCostSum: Boolean!
  ) {
    maTransactionsAggregates(by: $by, filters: $filters) {
      type
      group
      valuationsSum @include(if: $valuationsSum)
      reservesSum @include(if: $reservesSum)
      productionsSum @include(if: $productionsSum)
      acquisitionCostSum @include(if: $acquisitionCostSum)
    }
  }
`;

export const MA_TRANSACTIONS_AGGREGATES_STACKED_QUERY = gql`
  query maTransactionsAggregatesStacked(
    $by: MaAggregateByEnum!
    $stackBy: MaStackByEnum!
    $fields: [String!]!
    $filters: MaFilters
  ) {
    maTransactionsAggregatesStacked(
      by: $by
      stackBy: $stackBy
      fields: $fields
      filters: $filters
    ) {
      stacks
      data {
        type
        group
        values
      }
    }
  }
`;

export const MA_DEAL_COUNT_BY_TYPE_QUERY = gql`
  query maDealCountByType($by: MaAggregateByEnum!, $filters: MaFilters) {
    maDealCountByType(by: $by, filters: $filters) {
      stacks
      data {
        type
        group
        values
      }
    }
  }
`;
