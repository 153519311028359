// Local storage keys
export const ACCESS_TOKEN_KEY = 'access-token';
export const AUTHORIZATION_KEY = 'authorization';
export const CLIENT_KEY = 'client';
export const TOKEN_EXPIRY_KEY = 'expiry';
export const TOKEN_TYPE_KEY = 'tokenType';
export const UID_KEY = 'uid';
export const USER_ID = 'userId';
export const LOCAL_STORAGE_KEY_MAP_UNITS = 'mapUnits';

export const SESSIONS_STORAGE_KEY_GROUP_BY_PREFERENCE =
  'IS_GROUP_BY_REGION_COUNTRY_AREA_AUTOCOMPLETE';
export const SESSIONS_STORAGE_KEY_NOTES_VISIBLE_PREFERENCE =
  'SESSIONS_STORAGE_KEY_NOTES_VISIBLE_PREFERENCE';

export const SESSION_STORAGE_KEY_MA_OV_CH1_X = 'SESSION_STORAGE_KEY_MA_OV_CH1_X';
export const SESSION_STORAGE_KEY_MA_OV_CH1_STACK = 'SESSION_STORAGE_KEY_MA_OV_CH1_STACK';
export const SESSION_STORAGE_KEY_MA_OV_CH1_SY = 'SESSION_STORAGE_KEY_MA_OV_CH1_SY';
export const SESSION_STORAGE_KEY_MA_OV_CH2_X = 'SESSION_STORAGE_KEY_MA_OV_CH2_X';

export const SESSION_STORAGE_KEY_MA_CA_CH3_X = 'SESSION_STORAGE_KEY_MA_CA_CH3_X';
export const SESSION_STORAGE_KEY_MA_CA_CH3_STACK = 'SESSION_STORAGE_KEY_MA_CA_CH3_STACK';
export const SESSION_STORAGE_KEY_MA_CA_CH3_Y = 'SESSION_STORAGE_KEY_MA_CA_CH3_Y';
export const SESSION_STORAGE_KEY_MA_CA_CH3_SY = 'SESSION_STORAGE_KEY_MA_CA_CH3_SY';
export const SESSION_STORAGE_KEY_MA_CA_CH4_X = 'SESSION_STORAGE_KEY_MA_CA_CH4_X';

export const SESSION_STORAGE_KEY_MA_CB_CH1_X = 'SESSION_STORAGE_KEY_MA_CB_CH1_X';
export const SESSION_STORAGE_KEY_MA_CB_CH1_STACK = 'SESSION_STORAGE_KEY_MA_CB_CH1_STACK';
export const SESSION_STORAGE_KEY_MA_CB_CH2_X = 'SESSION_STORAGE_KEY_MA_CB_CH2_X';
export const SESSION_STORAGE_KEY_MA_CB_CH2_STACK = 'SESSION_STORAGE_KEY_MA_CB_CH2_STACK';

// Paths
export const ROOT_PATH = '/';
export const DEFAULT_PATH = '*';
export const PORTFOLIO_ANALYSIS_PATH = '/portfolio-analysis';

// M&A Paths
export const M_A_BASIC_PATH = '/m-a';
export const M_A_OVERVIEW_PATH = '/m-a/overview';
export const M_A_TRANSACTION_TABLE_PATH = '/m-a/transaction-table';
export const M_A_TRANSACTION_ANALYTICS_PATH = '/m-a/transaction_analytics';
export const M_A_COMPANY_BENCH_MARKING_PATH = '/m-a/company-bench-marking';
export const M_A_POTENTIAL_OPPORTUNITIES_PATH = '/m-a/potential-opportunities';
// M&A V2 Paths
export const M_A_BASIC_PATH_V2 = `${M_A_BASIC_PATH}/v2`;
export const M_A_OVERVIEW_PATH_V2 = `${M_A_BASIC_PATH_V2}/#overview`;
export const M_A_TRANSACTION_TABLE_PATH_V2 = `${M_A_BASIC_PATH_V2}/#transactions`;
export const M_A_TRANSACTION_ANALYTICS_PATH_V2 = `${M_A_BASIC_PATH_V2}/#analytics`;
export const M_A_COMPANY_BENCH_MARKING_PATH_V2 = `${M_A_BASIC_PATH_V2}/#analysis`;

// Video Tutorials Paths
export const VIDEO_TUTORIALS_PATH = '/tutorials';

export const COUNTRY_OVERVIEWS_PATH = '/country-overviews';
export const CCUS_PATH = '/ccus';
export const DATA_DOWNLOADS_PATH = '/data-downloads';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const GHG_PATH = '/ghg';
export const REGISTER_PATH = '/register';
export const RESET_PASSWORD_PATH = '/reset-password';
export const SETTINGS_PATH = '/settings';
export const SIGN_IN_PATH = '/login';
export const SIGN_UP_PATH = '/sign-up';

export const GUIDANCE_DOCUMENTATION_PATH = '/guidance-documentation';
// TODO #1059 - Uncomment when price assumptions page is ready
// export const PRICE_ASSUMPTIONS_PATH = '/guidance-documentation/price-assumptions';
export const RESERVES_DEFINITION_PATH = '/guidance-documentation/reserves-definition';
export const WEA_ASSUMPTIONS_PATH = '/guidance-documentation/wea-assumptions';

// Customer Paths
export const CUSTOMER_PATH = '/customer';
export const PROPRIETARY_DOWNLOADS_PATH = `${CUSTOMER_PATH}/proprietary-downloads`;
export const CUSTOMER_DOWNLOADS_PATH = `${CUSTOMER_PATH}/proprietary-downloads/:slug`;

// Oil and Gas Map Paths
export const OIL_AND_GAS_MAP_PATH = '/oil-and-gas-map';
export const OIL_AND_GAS_MAP_PATH_WITH_LATLONG = `${OIL_AND_GAS_MAP_PATH}/:latLong`;
export const OIL_AND_GAS_MAP_PATH_WITH_LATLONG_AND_ZOOM = `${OIL_AND_GAS_MAP_PATH}/:latLong/:zoom`;

// Valuations Paths
export const VALUATIONS_PATH = '/valuations';
export const VALUATIONS_ASSET_PATH = `${VALUATIONS_PATH}/:countryIsoCode/:legacyId`;
export const VALUATIONS_ASSET_REPORT_PATH = `${VALUATIONS_PATH}/:countryIsoCode/:legacyId/:report`;

export const PUBLIC_PATHS = [
  FORGOT_PASSWORD_PATH,
  REGISTER_PATH,
  RESET_PASSWORD_PATH,
  SETTINGS_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  RESERVES_DEFINITION_PATH,
  WEA_ASSUMPTIONS_PATH,
  GUIDANCE_DOCUMENTATION_PATH,
];

export const CCUS_PATHS = [CCUS_PATH];

export const M_A_PATHS = [
  M_A_BASIC_PATH,
  M_A_OVERVIEW_PATH,
  M_A_TRANSACTION_TABLE_PATH,
  M_A_TRANSACTION_ANALYTICS_PATH,
  M_A_COMPANY_BENCH_MARKING_PATH,
  M_A_POTENTIAL_OPPORTUNITIES_PATH,
  // M&A V2 Paths
  M_A_BASIC_PATH_V2,
  M_A_OVERVIEW_PATH_V2,
  M_A_TRANSACTION_TABLE_PATH_V2,
  M_A_TRANSACTION_ANALYTICS_PATH_V2,
  M_A_COMPANY_BENCH_MARKING_PATH_V2,
];

export const INTELLIGENCE_PATHS = [PROPRIETARY_DOWNLOADS_PATH, CUSTOMER_DOWNLOADS_PATH];

export const PORTFOLIO_ANALYSIS_PATHS = [PORTFOLIO_ANALYSIS_PATH];

export const GHG_PATHS = [GHG_PATH];

export const WEB_BASIC_PATHS = [
  COUNTRY_OVERVIEWS_PATH,
  DATA_DOWNLOADS_PATH,
  OIL_AND_GAS_MAP_PATH,
  VALUATIONS_PATH,
  VIDEO_TUTORIALS_PATH,
];

export const PATHS = [
  ...WEB_BASIC_PATHS,
  ...PUBLIC_PATHS,
  ...CCUS_PATHS,
  ...M_A_PATHS,
  ...INTELLIGENCE_PATHS,
  ...PORTFOLIO_ANALYSIS_PATHS,
  ...GHG_PATHS,
];

// Mapbox
export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoid2VsbGlnZW5jZSIsImEiOiJja2R1ZnMxZ2gwMGx6MnJwOW5yN2NoNHloIn0.a4F_hjdtzq0urr9MweXwnw';

// Wordpress Links
export const COOKIE_POLICY = 'https://welligence.com/cookie-policy/';
export const PRIVACY_POLICY = 'https://welligence.com/privacy-policy/';
export const TERMS_OF_USE = 'https://welligence.com/terms-of-use/';

// Feature Flag
export const SHINY_APPS_DEACTIVATED_IN_ENV = process.env.SHINY_APPS_DEACTIVATED_IN_ENV === 'true';

// New Feature Walkthrough Links
const newFeatureLinks = [
  {
    path: PORTFOLIO_ANALYSIS_PATH,
    key: 'PortfolioAnalysis',
  },
  {
    path: CCUS_PATH,
    key: 'CCUS',
  },
];
export const getNewFeature = (path) => {
  return newFeatureLinks.find((link) => path.match(`^${link.path}`));
};

// get current path
export const getCurrentPath = (path) => {
  return PATHS.find((p) => path.match(`^${p}`));
};

export const ANALYTICS_EVENT_ACTIONS = {
  buttonClick: 'ButtonClick',
  download: 'Download',
  filter: 'Filter',
  singleFilter: 'SingleFilter',
  layer: 'Layer',
  search: 'Search',
  chartSettings: 'chart_settings',
};
export const ANALYTICS_EVENTS = {
  login: 'login',
  pageview: 'pageview',
  event: 'event',
};

// Clients subscriptions
// Note: These need to match the feature names in the database
export const WEB_BASIC_SUBSCRIPTION = 'Web Basic Subscription';
export const CCUS_SUBSCRIPTION = 'CCUS';
export const M_A_SUBSCRIPTION = 'Mergers and Acquisitions';
export const INTELLIGENCE_SUBSCRIPTION = 'Intelligence Notes';
export const PORTFOLIO_ANALYSIS_SUBSCRIPTION = 'Portfolio Analysis';
export const GHG_SUBSCRIPTION = 'GHG';
export const FEATURE_NOT_AVAILABLE_TOOLTIP =
  'This feature is not part of your subscription. Please contact your account team to discuss options for access.';

// Temporary solution for trials (admins, jefferies trial, goldman sachs trials, evercore)
// TODO: Remove if not needed for future features?
// export const consolidatedCashflowsAllowedUserIds = [
//   1675, 1689, 1690, 1236, 1688, 1235, 1668, 1252, 1170, 1669, 1673, 1205, 1674, 1244, 1678, 1679,
//   1222, 1080, 1199, 1217, 1145, 1212, 1480, 1676, 1677, 880, 1221, 1158, 1143, 1695, 175, 883, 1092,
//   786, 1529, 1196, 757, 434, 1079, 1204, 613, 1072, 986, 1011, 1393, 1036, 980, 490, 408, 1293, 811,
//   1356, 840, 984, 754, 1258, 1294, 723, 835, 751, 1587, 1588, 1192, 782, 839, 1203, 572, 928, 808,
//   790, 1404, 701, 1631, 804, 822, 812, 1661, 758, 981, 729, 1364, 1736, 88, 97, 108, 114, 139, 156,
//   167, 182, 185, 201, 207, 209, 235, 244, 261, 365, 414, 496, 562, 621, 617, 989, 184, 1756, 216,
//   1102, 974, 1438, 362, 317, 341, 245, 1662, 1654, 973, 676, 1440, 393, 238, 1530, 1531, 1532, 1607,
//   1608, 970, 1659, 1666, 447, 756, 1067, 1759,
// ];

// Global search URL parameter keys
//
// The ft_action should take only the GLOBAL_SEARCH_ACTION_* constant.
export const GLOBAL_SEARCH_URL_ACTION = 'ft_action';

// ft_legacy_id, ft_iso_code and ft_name are used to identify
// an entity (asset, block, country etc.) on screens
// no all will be always non-null, but their combination
// should be non-null to identify the entity sufficiently
export const GLOBAL_SEARCH_URL_LEGACY_ID = 'ft_legacy_id';
export const GLOBAL_SEARCH_URL_ISO_CODE = 'ft_iso_code';
export const GLOBAL_SEARCH_URL_NAME = 'ft_name';
export const GLOBAL_SEARCH_URL_GEOM = 'ft_geom';
export const GLOBAL_SEARCH_URL_COUNTRY_GEOM = 'ft_country_geom';

// Entity is a value introduced by global search feature.
// It comes from the GQL backend--each result has one--and
// it can take the following values: ASSET, BLOCK, COUNTRY,
// COMPANY, LEASE, PARTICIPATION
export const GLOBAL_SEARCH_URL_ENTITY = 'ft_entity';

// Global search actions
// Currently only the 'detail' action is supported
// The future, potential actions are:
//    'add' for adding to the current filter on a screen
//    'download' for downloading a xls, pdf etc.
export const GLOBAL_SEARCH_ACTION_DETAIL = 'detail';

const subject = 'Welligence Onboarding Session';
const formattedBody =
  "I'd like to schedule a platform onboarding session on the proposed date/time below:\n\nDate:\nTime:\n\nThanks";
export const requestTrainingMailTo = `mailto:info@welligence.com?subject=${encodeURIComponent(
  subject,
)}&body=${encodeURIComponent(formattedBody)}`;

// HARD-CODED HYDROCARBON TYPES
// TODO: Move to the backend
export const HYDROCARBON_TYPES = [
  { id: 1, oil: 'extra_heavy', type: 'oil', name: 'Extra Heavy Oil (<10° API)' },
  { id: 2, oil: 'heavy', type: 'oil', name: 'Heavy Oil (10° - 22° API)' },
  { id: 3, oil: 'medium', type: 'oil', name: 'Medium Oil (22° - 31° API)' },
  { id: 4, oil: 'light', type: 'oil', name: 'Light Oil (>31° API)' },
  { id: 5, oil: null, type: 'gas', name: 'Gas' },
];
