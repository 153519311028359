import { useCallback, useState } from 'react';
import { GLOBAL_SEARCH_EVENTS, GLOBAL_SEARCH_GOOGLE_ANALYTICS_TAG } from './constants';
import { getUserId } from '../../utils/auth';
import { defaultDimensions } from '../../utils/analytics';
import { gtag } from '../../utils/gtag';

const SESSIONS_STORAGE_KEY_GS_HISTORY = 'gsHistory';

const getGlobalSearchHistory = () => {
  const history = JSON.parse(sessionStorage.getItem(SESSIONS_STORAGE_KEY_GS_HISTORY));
  if (Array.isArray(history)) {
    return history;
  }
  return [];
};

export const storeGlobalSearchHistory = (inputText) => {
  const history = JSON.parse(sessionStorage.getItem(SESSIONS_STORAGE_KEY_GS_HISTORY));

  let newHistory;
  if (Array.isArray(history)) {
    if (history.includes(inputText)) return;

    newHistory = [inputText, ...history.slice(0, 4)];
  } else {
    newHistory = [inputText];
  }

  sessionStorage.setItem(SESSIONS_STORAGE_KEY_GS_HISTORY, JSON.stringify(newHistory));

  return newHistory;
};

export const useGlobalSearchController = () => {
  const [showModal, setShowModal] = useState(false);
  const [inputText, setInputText] = useState('');
  const [inputTextLast, setInputTextLast] = useState('');
  const [isOpenSearchTrayState, setIsOpenSearchTray] = useState(false);
  const [history, setHistory] = useState(getGlobalSearchHistory());

  const setInputTextLocal = useCallback((text) => {
    setInputText(text);
    setInputTextLast(text);
    if (text) {
      setShowModal(true);
      setIsOpenSearchTray(true);
    }
  }, []);

  const openModal = useCallback(() => {
    setShowModal(true);
    setIsOpenSearchTray(true);
  }, []);

  const setIsOpenSearchTrayLocal = useCallback((value) => {
    if (value) {
      setIsOpenSearchTray(value);
    } else {
      setIsOpenSearchTray(value);
      setInputText('');
      setShowModal(false);
    }
  }, []);

  const closeModal = useCallback(() => {
    setIsOpenSearchTrayLocal(false);
  }, []);

  const onHistoryItemClick = useCallback((historyText) => {
    gtag('event', GLOBAL_SEARCH_EVENTS.event, {
      event_category: GLOBAL_SEARCH_GOOGLE_ANALYTICS_TAG,
      event_action: GLOBAL_SEARCH_EVENTS.historyClick,
      event_label: 'search_bar_history_click',
      userId: getUserId(),
      ...defaultDimensions,
    });
    setInputTextLocal(historyText);
  }, []);

  return {
    history,
    showModal,
    inputText,
    inputTextLast,
    isOpenSearchTrayState,
    setInputTextLocal,
    openModal,
    closeModal,
    onHistoryItemClick,
    setIsOpenSearchTrayLocal,
    setHistory,
  };
};
