// Method to build the URL for API calls. If we need to change the URL, we only need to change it in one place.
export const getUrl = (uri) => `${window.location.origin}/${uri}`;

export const getQueryParams = (params) => {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        queryParams.append(`${key}[]`, v);
      });
    } else {
      queryParams.append(key, value);
    }
  });

  return queryParams.toString();
};
