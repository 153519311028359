import { DEVELOPMENT_STATUS_WITH_FILTER_QUERY } from '../../../../Filters/operations';
import { FilterKeys } from '../../../../Filters';
import { useFilters } from '../../../FiltersContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetDevelopmentStatuses = () => {
  const { filters } = useFilters();

  const filterVariables = useMemo(
    () => ({
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
    }),
    [filters],
  );

  const { data, loading, error } = useQuery(DEVELOPMENT_STATUS_WITH_FILTER_QUERY, {
    variables: { ...filterVariables },
  });

  return {
    developmentStatuses: data?.developmentStatusWithFilter || [],
    loading,
    error,
  };
};
