import React, { useState, useEffect, useRef, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import AssetListItem from './assetListItem';
import { ASSETS_QUERY, WATCH_LIST_QUERY } from './operations';
import { roundAndFormat } from '../../../utils/helpers';
import WalkthroughContext from '../../WalkthroughContext';
import Loading from '../../Loading';
import { FilterKeys } from '../../Filters/filterTypes';

const useStyles = makeStyles((theme) => ({
  assetListHeader: {
    ...theme.palette.secondaryBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: '0 1 auto',
    zIndex: 1,
  },
  assetListHeaderText: {
    padding: '12px 36px',
    [theme.breakpoints.down('xl')]: {
      padding: '12px 10px',
    },
    '&:first-child': {
      paddingRight: '5px',
    },
    '&:last-child': {
      paddingLeft: '5px',
    },
  },
  assetListSortContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#f5f5f5',
    justifyContent: 'space-evenly',
  },
  assetListSort: {
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'center',
    padding: '5px',
    flex: '0 1 auto',
    [theme.breakpoints.down('xl')]: {
      fontSize: '8px',
      padding: '5px',
    },
    cursor: 'pointer',
  },
  assetListItemsContainer: {
    flex: '0 1 auto',
    overflow: 'auto',
    flexGrow: 1,
    ...theme.palette.scrollbar,
  },
  empty: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '10px',
    boxSizing: 'border-box',
  },
  loadMore: {
    width: '100%',
    border: 'solid 1px #f2f5f7',
    display: 'flex',
    padding: '25px 0 25px',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
}));

// will be used to observe scrolling
let observer = null;

export default ({
  filters,
  filterChanged,
  selectedAsset,
  setAsset,
  setFilterChanged,
  watchList,
  updateWatchList,
  setUpdateWatchList,
  shoreStatusData,
}) => {
  const classes = useStyles();
  const { walkthrough } = useContext(WalkthroughContext);
  // keep track of when to load next "page" for infinite scroll
  const rootLoader = useRef(null);
  const loader = useRef(null);

  // sort type for asset list - name, npv, reserves
  // sort direction for asset list
  // true = a -> z / min -> max
  // false = z -> a / max -> min
  const [sortType, setSortType] = useState({ label: 'NPV', filter: 'npv' });
  const [sortAscending, setSortAscending] = useState(false);
  const [sortedAssets, setSortedAssets] = useState([]);
  const [menuEl, setMenuEl] = useState(null);
  // tell if assets fetched for infinite scroll
  const [infiniteScroll, setInfiniteScroll] = useState(false);
  // tell if asset should be autoselected when using new filter
  const [shouldAutoSelectAsset, setShouldAutoSelectAsset] = useState(false);
  const handleMenuClick = (event) => {
    setMenuEl(event.currentTarget);
  };
  const handleMenuClose = (value) => {
    if (value !== undefined) {
      setSortType(value);
      setSortAscending(value.label === 'NAME');
      setFilterChanged(true);
    }
    setMenuEl(null);
  };

  // If the Deepwater filter is selected, add the Pre-salt filter
  // to the query if it is not already selected:
  const shoreStatus = [...filters[FilterKeys.shoreStatuses]];
  const findShoreStatus = (list, name) => list?.find((a) => a.name.toLowerCase() === name);
  const deepwater = findShoreStatus(shoreStatus, 'deepwater');
  if (deepwater && !findShoreStatus(shoreStatus, 'pre-salt')) {
    const preSalt = findShoreStatus(shoreStatusData?.shoreStatus, 'pre-salt');
    preSalt && shoreStatus.push(preSalt);
  }

  const variables = {
    assetLegacyIds: filters[FilterKeys.assets].map((a) => a.legacyId),
    assetIsoCodes: filters[FilterKeys.assets].map((a) => a.country.isoCode),
    country: filters[FilterKeys.countries].map((a) => a.isoCode),
    company: filters[FilterKeys.companies].map((a) => a.name),
    basin: filters[FilterKeys.basins].map((a) => a.name),
    shoreStatus: shoreStatus.map((a) => a.id),
    hydrocarbonType: filters[FilterKeys.hydrocarbons].map((a) => a.type),
    oilType: filters[FilterKeys.hydrocarbons].filter((a) => a).map((a) => a.oil),
    npvMin: parseFloat(filters[FilterKeys.npvMin]),
    npvMax: parseFloat(filters[FilterKeys.npvMax]),
    operator: filters[FilterKeys.operator],
    opportunities: filters[FilterKeys.opportunities],
    reservesMin: parseFloat(filters[FilterKeys.reservesMin]),
    reservesMax: parseFloat(filters[FilterKeys.reservesMax]),
    productionMin: parseFloat(filters[FilterKeys.productionMin]),
    productionMax: parseFloat(filters[FilterKeys.productionMax]),
    developmentStatus: filters[FilterKeys.developmentStatuses].map((a) => a.id),
    // formation: filters[FilterKeys.Formation].map((a) => a.id),
    developmentType: filters[FilterKeys.developmentTypes].map((a) => a.id),
    depthMin: parseFloat(filters[FilterKeys.depthMin]),
    depthMax: parseFloat(filters[FilterKeys.depthMax]),
    isWalkthrough: walkthrough,
    first: 10,
    sortType: sortType.filter,
    sortAscending,
  };

  const {
    loading: loadingWatchList,
    data: watchListData,
    refetch: refetchWatchList,
  } = useQuery(WATCH_LIST_QUERY, {
    variables: {
      ...variables,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [getAssets, { loading: loadingAssets, data: assetsData, refetch: refetchAssets }] =
    useLazyQuery(ASSETS_QUERY, {
      variables,
    });

  useEffect(() => {
    if (!watchList && !loadingAssets && assetsData) {
      if (infiniteScroll) {
        setSortedAssets(sortedAssets.concat(assetsData.assetsConnection.edges.map((n) => n.node)));
        setInfiniteScroll(false);
        setFilterChanged(false);
      } else {
        // check that the page being fetched is a new page
        if (filterChanged) {
          setSortedAssets(assetsData.assetsConnection.edges.map((n) => n.node));
          setFilterChanged(false);
        }
        if (!updateWatchList) {
          if (assetsData.assetsConnection.edges.length > 0) {
            if (shouldAutoSelectAsset) {
              setAsset(assetsData.assetsConnection.edges[0].node);
              setShouldAutoSelectAsset(false);
            }
          } else {
            setAsset(null);
          }
        } else {
          setUpdateWatchList(false);
        }
      }
    }
  }, [assetsData, watchList]);

  useEffect(() => {
    if (!loadingWatchList && watchListData) {
      if (watchList) {
        setSortedAssets(watchListData.filterWatchList);
        if (watchListData.filterWatchList.length > 0) {
          setAsset(watchListData.filterWatchList[0]);
        } else {
          setAsset(null);
        }
      }
      if (updateWatchList) {
        setUpdateWatchList(false);
      }
    }
  }, [watchListData, watchList]);

  useEffect(() => {
    setShouldAutoSelectAsset(true);
    if (!watchList) {
      if (refetchAssets) {
        refetchAssets({ ...variables, after: null });
        setFilterChanged(true);
      } else {
        getAssets();
        setFilterChanged(true);
      }
    }
    refetchWatchList({ ...variables });
  }, [filters, watchList, walkthrough]);

  const getTotalAssets = () => {
    if (watchList && !loadingWatchList) {
      return watchListData.filterWatchList.length;
    } else if (!loadingAssets && assetsData?.assetsConnection)
      return roundAndFormat(assetsData.assetsConnection.totalCount);
    return '-';
  };

  useEffect(() => {
    if (updateWatchList) {
      refetchWatchList({ ...variables });
    }
  }, [updateWatchList]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setInfiniteScroll(true);
      refetchAssets({ ...variables, after: assetsData.assetsConnection.pageInfo.endCursor });
    }
  };

  useEffect(() => {
    let options = {
      root: rootLoader.current,
      rootMargin: '0px',
      threshold: 0.8,
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    if (observer !== null) {
      observer.unobserve(loader.current);
    }
    observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [assetsData]);

  const handleSortClick = () => {
    setSortAscending(!sortAscending);
    setFilterChanged(true);
  };

  return (
    <>
      <AppBar className={classes.assetListHeader} position="sticky">
        <Typography variant="body1" className={classes.assetListHeaderText}>
          ASSET LIST
        </Typography>
        <Typography variant="body1" className={classes.assetListHeaderText}>
          TOTAL: {getTotalAssets()}
        </Typography>
      </AppBar>
      <div className={classes.assetListSortContainer}>
        <Tooltip title="Change Sort Type" aria-label="sort type">
          <Typography variant="caption" className={classes.assetListSort} onClick={handleMenuClick}>
            SORT BY {sortType.label}:
          </Typography>
        </Tooltip>
        <Tooltip title="Sort List" aria-label="sort list">
          <Typography variant="body2" className={classes.assetListSort} onClick={handleSortClick}>
            ↑↓
          </Typography>
        </Tooltip>
      </div>
      <Menu anchorEl={menuEl} keepMounted open={Boolean(menuEl)} onClose={() => handleMenuClose()}>
        <MenuItem onClick={() => handleMenuClose({ label: 'NAME', filter: 'display_name' })}>
          Name
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose({ label: 'NPV', filter: 'npv' })}>NPV</MenuItem>
        <MenuItem
          onClick={() => handleMenuClose({ label: 'RESERVES', filter: 'remaining_reserves' })}
        >
          Reserves
        </MenuItem>
      </Menu>
      <div ref={rootLoader} className={classes.assetListItemsContainer}>
        {((loadingWatchList && watchList) || (loadingAssets && !watchList)) && !infiniteScroll ? (
          <Loading />
        ) : watchList && watchListData.filterWatchList.length > 0 ? (
          watchListData.filterWatchList.map((asset) => (
            <AssetListItem
              key={`watchlist-${asset.id}-${asset.displayName}-${sortType}-${sortAscending}`}
              asset={asset}
              setAsset={setAsset}
              isSelected={selectedAsset !== null && selectedAsset.id === asset.id}
              isWatchList={true}
              sortType={sortType}
            />
          ))
        ) : watchList && watchListData.filterWatchList.length === 0 ? (
          <Typography className={classes.empty}>No assets in your watch list!</Typography>
        ) : sortedAssets.length > 0 ? (
          sortedAssets.map((asset) => (
            <AssetListItem
              key={`assets-${asset.id}-${asset.displayName}-${sortType}-${sortAscending}`}
              asset={asset}
              setAsset={setAsset}
              isSelected={selectedAsset !== null && selectedAsset.id === asset.id}
              isWatchList={
                !loadingWatchList && watchListData
                  ? watchListData.filterWatchList.find((a) => a.id === asset.id)
                  : false
              }
              sortType={sortType}
            />
          ))
        ) : (
          <Typography className={classes.empty}>0 assets match your selected filters</Typography>
        )}
        <div
          className={classes.loadMore}
          ref={loader}
          style={
            !loadingWatchList &&
            !loadingAssets &&
            !watchList &&
            assetsData &&
            assetsData.assetsConnection.pageInfo.hasNextPage
              ? {}
              : { display: 'none' }
          }
        >
          <Typography>Load More...</Typography>
        </div>
      </div>
    </>
  );
};
