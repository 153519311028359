import { getChosenMap, getDoubleChosenMap } from './getChosenMap';
import { calculateData } from './calculateData';
import { getAllYears } from './getAllYears';
import { useMaFilterContext } from './MaFilterProvider';
import { useMemo } from 'react';

/**
 * Iterates through Merges and Acquisitions data set from Database by applying Filters and iteratively calling 
 * calculatePageContentData callback param.
 *
 * @param data - data from GraphQL query (from Ruby backend, from DB) - ma_transactions.rb
 * @param pageContentData - object reference with initial values, to be filled by callback passed
 *   as next param (calculatePageContentData)
 * @param calculatePageContentData - callback called with every MA transaction
 * @param memoBreakValue1 - chosen graph type (just to re-trigger calcuation on user chart settings change)
 * @param memoBreakValue2 - same as above
 * @param memoBreakValue3 - same as above
 * @param memoBreakValue4 - same as above
 * @param memoBreakValue5 - same as above
 * @param memoBreakValue6 - same as above
 * @returns filter data and content for the page charts and tables
 */
export const useDataOnUpdate = (
  data,
  pageContentData,
  calculatePageContentData,
  memoBreakValue1,
  memoBreakValue2,
  memoBreakValue3,
  memoBreakValue4,
  memoBreakValue5,
  memoBreakValue6,
) => {
  const { countryList, typeList, companyBuyerList, companySellerList, assetList, chosenYears } =
    useMaFilterContext();

  return useMemo(() => {
    if (!countryList || !typeList || !companyBuyerList || !companySellerList || !assetList)
      return {};
    const {
      obj: countryIdsEnabled,
      secondObj: regionChosenList,
      noChosen: noCountryChosen,
    } = getDoubleChosenMap(countryList, 'region');

    const {
      obj: typeNamesEnabled,
      secondObj: typeGroupChosenList,
      noChosen: noTypeChosen,
    } = getDoubleChosenMap(typeList, 'group');
    const { obj: companySellerIdsEnabled, noChosen: noCompanySellerChosen } =
      getChosenMap(companySellerList);
    const { obj: companyBuyerIdsEnabled, noChosen: noCompanyBuyerChosen } =
      getChosenMap(companyBuyerList);
    const { obj: assetNamesEnabled } = getChosenMap(assetList);

    let {
      pageContentDataResult,
      years,
      types,
      countriesObject,
      companySellers,
      companyBuyers,
      assets,
    } = calculateData(
      data,
      chosenYears,
      noCountryChosen,
      countryIdsEnabled,
      noTypeChosen,
      typeNamesEnabled,
      typeGroupChosenList,
      companySellerIdsEnabled,
      noCompanySellerChosen,
      companyBuyerIdsEnabled,
      noCompanyBuyerChosen,
      assetNamesEnabled,
      regionChosenList,
      pageContentData,
      calculatePageContentData,
    );

    const availableYearsForSlider = getAllYears(years);

    //GET SORTED FILTER OPTIONS LISTS
    const typesAvailableList = Object.values(types).sort((a, b) =>
      a.displayName.localeCompare(b.displayName),
    );

    const countryAvailableList = Object.values(countriesObject).sort((a, b) =>
      a.displayName.localeCompare(b.displayName),
    );
    const companySellerAvailableList = Object.values(companySellers).sort((a, b) =>
      a.displayName.localeCompare(b.displayName),
    );
    const companyBuyerAvailableList = Object.values(companyBuyers).sort((a, b) =>
      a.displayName.localeCompare(b.displayName),
    );
    const assetAvailableList = Object.values(assets).sort((a, b) =>
      a.displayName.localeCompare(b.displayName),
    );

    return {
      pageContentDataResult,

      filters: {
        availableYearsForSlider,
        typesAvailableList,
        countryAvailableList,
        assetAvailableList,
        companySellerAvailableList,
        companyBuyerAvailableList,
      },
    };
  }, [
    chosenYears,
    countryList,
    typeList,
    companyBuyerList,
    companySellerList,
    assetList,
    memoBreakValue1,
    memoBreakValue2,
    memoBreakValue3,
    memoBreakValue4,
    memoBreakValue5,
    memoBreakValue6,
  ]);
};
