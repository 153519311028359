import AggregateLimit from './aggregateLimit';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  aggregateLayer: {
    borderRight: 'solid thin rgba(0, 0, 0, 0.12)',
    '&:last-child': {
      border: 'none',
    },
  },
  checkboxRoot: {
    width: '100%',
  },
  checkboxRowLabel: {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  layerLabel: {
    width: '100%',
    borderBottom: 'solid thin rgba(0, 0, 0, 0.12)',
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
  },
  radioRowLabel: {
    fontWeight: 'bold',
  },
}));

export default ({
  aggregateLayers,
  aggregateTypes,
  handleSetAggregate,
  handleSetState,
  itemIsDisabledParams = {},
  selectedAggregate,
  selectedCheckboxAggregates,
  selectedSortDirection,
}) => {
  const classes = useStyles();

  // Disabled function for treemap layer aggregates
  const isAggregateLayerDisabled = (aggregate, i, layer) => {
    // Check if graph type is compatible with the aggregate
    const isDisabled = aggregate.isDisabled && aggregate.isDisabled(itemIsDisabledParams);
    // If not, disable button and uncheck
    if (isDisabled) {
      if (selectedAggregate[layer] === aggregate.name) {
        handleSetState('', 'aggregate', layer);
      }
      return true;
    }
    // Check if aggregate is already selected in another layer
    const found = Object.values(selectedAggregate).findIndex((a) => a === aggregate.name);
    return found !== -1 && found !== i;
  };

  const aggregateIsDisabled = (aggregate) =>
    aggregate.isDisabled && aggregate.isDisabled(itemIsDisabledParams);

  const handleUnsetAggregateLayer = (i, aggregate, layer) => (e) => {
    if (i > 0 && e.target.value === aggregate.name) {
      handleSetState('', 'aggregate', layer);
    }
  };

  return (
    <>
      {aggregateTypes && (
        <FormControl style={{ width: '100%', marginBottom: '5px' }}>
          <Typography className={classes.label} variant="overline">
            Aggregate Data
          </Typography>
          {/* This renders the aggregate layer option seen on the portfolio analysis treemap */}
          {aggregateLayers ? (
            <Grid container direction="row" spacing={1}>
              {aggregateLayers.map((layer, i) => (
                <Grid
                  className={classes.aggregateLayer}
                  container
                  direction="column"
                  key={`graph-aggregate-radio-layer-${layer}`}
                  size={4}
                >
                  <Typography className={classes.layerLabel} variant="overline">
                    {layer}
                  </Typography>
                  <RadioGroup
                    value={selectedAggregate[layer]}
                    name="graph-aggregate-radio-buttons-group"
                    onChange={(e) => handleSetState(e.target.value, 'aggregate', layer)}
                  >
                    <Grid
                      container
                      direction="column"
                      sx={{
                        justifyContent: 'space-around',
                      }}
                    >
                      {aggregateTypes.map((aggregate) => (
                        <Grid key={`checklist-${aggregate.name}`}>
                          <Tooltip title={aggregate.tooltip || ''}>
                            <FormControlLabel
                              classes={{ label: classes.radioRowLabel }}
                              value={aggregate.name}
                              control={
                                <Radio onClick={handleUnsetAggregateLayer(i, aggregate, layer)} />
                              }
                              label={aggregate.name}
                              disabled={isAggregateLayerDisabled(aggregate, i, layer)}
                            />
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container spacing={1}>
              {/* This renders the normal aggregate types (non layered) */}
              {aggregateTypes.map((aggregate) => (
                <Tooltip key={`checklist-${aggregate.name}`} title={aggregate.tooltip || ''}>
                  <Grid size={12}>
                    {/* This renders aggregate types with options enabled
                     (allows user to select top or bottom x amount of traces) */}
                    {aggregate.options ? (
                      <AggregateLimit
                        aggregate={aggregate}
                        aggregateIsDisabled={aggregateIsDisabled}
                        defaultSortDirection={selectedSortDirection}
                        handleSetAggregate={handleSetAggregate}
                        selectedAggregate={selectedAggregate}
                        styles={classes}
                      />
                    ) : aggregate.type === 'checkbox' ? (
                      // This renders aggregate checkboxes (can select multiple)
                      <FormControl component="fieldset" className={classes.checkboxRoot}>
                        <FormControlLabel
                          className={classes.checkboxRowLabel}
                          labelPlacement="start"
                          value={true}
                          control={
                            <Checkbox
                              color="primary"
                              checked={selectedCheckboxAggregates[aggregate.name]}
                              onClick={() =>
                                handleSetState(
                                  {
                                    ...selectedCheckboxAggregates,
                                    [aggregate.name]: !selectedCheckboxAggregates[aggregate.name],
                                  },
                                  'checkboxAggregates',
                                )
                              }
                            />
                          }
                          label={
                            <Typography className={classes.radioRowLabel}>
                              {aggregate.name}
                            </Typography>
                          }
                          disabled={aggregateIsDisabled(aggregate)}
                        />
                      </FormControl>
                    ) : (
                      // This renders the base aggregate (single option)
                      <RadioGroup
                        value={selectedAggregate}
                        name="graph-aggregate-radio-buttons-group"
                        onChange={() => handleSetAggregate(aggregate.name)}
                      >
                        <FormControlLabel
                          className={classes.radioRow}
                          classes={{
                            label: clsx({
                              [classes.radioRowLabel]: true,
                              [classes.disabled]: aggregateIsDisabled(aggregate),
                            }),
                          }}
                          checked={aggregate.name === selectedAggregate}
                          value={aggregate.name}
                          control={<Radio />}
                          disabled={aggregateIsDisabled(aggregate)}
                          label={
                            <Typography className={classes.radioRowLabel}>
                              {aggregate.name}
                            </Typography>
                          }
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    )}
                  </Grid>
                </Tooltip>
              ))}
            </Grid>
          )}
        </FormControl>
      )}
    </>
  );
};
