export const roundDownToNearestThousand = (num) => Math.floor(num / 1000) * 1000;
export const roundUpToNearestThousand = (num) => Math.ceil(num / 1000) * 1000;

export const getMarks = (from, to, isDate = false, intlNumberFormatProps = {}) => {
  if (typeof from !== 'number' || typeof to !== 'number') {
    return [];
  }

  const middleMarks = [];
  if (to - from > 1) {
    const difference = to - from;
    const distance = Math.floor(difference / 3);
    middleMarks.push({
      value: from + distance,
      label: isDate
        ? from + distance
        : new Intl.NumberFormat('en-US', intlNumberFormatProps).format(from + distance),
    });
    middleMarks.push({
      value: to - distance,
      label: isDate
        ? to - distance
        : new Intl.NumberFormat('en-US', intlNumberFormatProps).format(to - distance),
    });
  } else if (to - from > 0) {
    middleMarks.push({
      value: to - 1,
      label: to - 1,
    });
  }

  return [
    {
      value: from,
      label: isDate ? from : new Intl.NumberFormat('en-US', intlNumberFormatProps).format(from),
    },
    ...middleMarks,
    {
      value: to,
      label: isDate ? to : new Intl.NumberFormat('en-US', intlNumberFormatProps).format(to),
    },
  ];
};
