const TRANSACTIONS_REGION_FOR_DEAL_TYPE = {
  id: 1,
  displayName: 'Transactions',
};

const LICENSING_REGION_FOR_DEAL_TYPE = {
  id: 2,
  displayName: 'Licensing',
};

const OTHER_REGION_FOR_DEAL_TYPE = {
  id: 3,
  displayName: 'Other',
};

export const getGroupForDealType = (dealType, typeRegionChosenList = {}) => {
  switch (dealType) {
    case 'Asset Deal':
    case 'Asset Swap':
    case 'Corporate M&A':
    case 'Package Deal':
      return {
        ...TRANSACTIONS_REGION_FOR_DEAL_TYPE,
        isChosen: !!typeRegionChosenList[TRANSACTIONS_REGION_FOR_DEAL_TYPE.displayName],
      };
    case 'Discovered Resource Opportunity Award':
    case 'License Award':
    case 'License End':
    case 'Relinquishment':
      return {
        ...LICENSING_REGION_FOR_DEAL_TYPE,
        isChosen: !!typeRegionChosenList[TRANSACTIONS_REGION_FOR_DEAL_TYPE.displayName],
      };
    default:
      return {
        ...OTHER_REGION_FOR_DEAL_TYPE,
        isChosen: !!typeRegionChosenList[OTHER_REGION_FOR_DEAL_TYPE.displayName],
      };
  }
};

export const calculateData = (
  data,
  chosenYears,
  noCountryChosen,
  countryIdsEnabled,
  noTypeChosen,
  typeNamesEnabled,
  typeGroupChosenList,
  companySellerIdsEnabled,
  noCompanySellerChosen,
  companyBuyerIdsEnabled,
  noCompanyBuyerChosen,
  assetNamesEnabled,
  regionChosenList,
  pageContentDataParam,
  calculatePageContentData,
) => {
  const pageContentData = { ...pageContentDataParam };
  //is mutated by calculatePageContentData
  let years = {};
  let types = {};
  let countriesObject = {};
  let companySellers = {};
  let companyBuyers = {};
  let assets = {};

  // FOR CLASSIC LOOP IS USED FOR BETTER PERFORMANCE
  for (let i = 0; i < data.length; i++) {
    const dealsByCountry = data[i];
    for (let r = 0; r < dealsByCountry?.length || 0; r++) {
      const deal = dealsByCountry[r];
      if (deal.effectiveDate) {
        const year = deal.effectiveDate.substr(0, 4);

        //CONDITIONS
        const yearCondition =
          (!chosenYears.from || chosenYears.from <= year) &&
          (!chosenYears.to || year <= chosenYears.to);
        const typeCondition = noTypeChosen || typeNamesEnabled[deal.dealType];
        let companySellerConditionDealLevel = noCompanySellerChosen;
        let companyBuyerConditionDealLevel = noCompanyBuyerChosen;

        deal.maCompanyTransactions.forEach((companyTransaction) => {
          let companySellerCondition = noCompanySellerChosen;
          let companyBuyerCondition = noCompanyBuyerChosen;
          if (
            companyTransaction.sellerCompanyId &&
            companySellerIdsEnabled[companyTransaction.sellerCompanyId]
          ) {
            companySellerCondition = true;
            companySellerConditionDealLevel = true;
          }
          if (
            companyTransaction.buyerCompanyId &&
            companyBuyerIdsEnabled[companyTransaction.buyerCompanyId]
          ) {
            companyBuyerCondition = true;
            companyBuyerConditionDealLevel = true;
          }

          let countryConditionAtCompanyTransactionLevel = noCountryChosen;
          companyTransaction.maAssetTransactions.forEach((assetTransaction) => {
            countryConditionAtCompanyTransactionLevel =
              countryConditionAtCompanyTransactionLevel ||
              countryIdsEnabled[assetTransaction.countryId];
            const countryCondition =
              noCountryChosen || countryIdsEnabled[assetTransaction.countryId];

            if (yearCondition && typeCondition && companySellerCondition && companyBuyerCondition) {
              const assetFilterPresent = Object.keys(assetNamesEnabled || {}).length > 0;
              const assetCondition =
                !assetFilterPresent || assetNamesEnabled[assetTransaction.assetName];

              if (assetTransaction.assetName) {
                //COUNTRIES
                countriesObject[assetTransaction.countryId] = {
                  id: assetTransaction.countryId,
                  displayName: assetTransaction.countryName,
                  isChosen: !!countryIdsEnabled[assetTransaction.countryId],
                  region: {
                    id: assetTransaction.regionName,
                    displayName: assetTransaction.regionName,
                    isChosen: !!regionChosenList[assetTransaction.regionName],
                  },
                };

                if (countryCondition) {
                  //ASSETS
                  assets[assetTransaction.assetName] = {
                    id: assetTransaction.assetName,
                    displayName: assetTransaction.assetName,
                    isChosen: !!assetNamesEnabled[assetTransaction.assetName],
                  };

                  if (assetCondition) {
                    // calling callback from page controllers
                    calculatePageContentData(
                      pageContentData,
                      year,
                      deal,
                      companyTransaction,
                      assetTransaction,
                    );
                  }
                }
              }
            }
          });

          if (yearCondition && typeCondition && countryConditionAtCompanyTransactionLevel) {
            //COMPANIES
            if (
              companySellerCondition &&
              companyTransaction.buyerCompanyId &&
              companyTransaction.buyerCompanyName
            ) {
              companyBuyers[companyTransaction.buyerCompanyId] = {
                id: companyTransaction.buyerCompanyId,
                displayName: companyTransaction.buyerCompanyName,
                isChosen: !!companyBuyerIdsEnabled[companyTransaction.buyerCompanyId],
              };
            }
            if (
              companyBuyerCondition &&
              companyTransaction.sellerCompanyId &&
              companyTransaction.sellerCompanyName
            ) {
              companySellers[companyTransaction.sellerCompanyId] = {
                id: companyTransaction.sellerCompanyId,
                displayName: companyTransaction.sellerCompanyName,
                isChosen: !!companySellerIdsEnabled[companyTransaction.sellerCompanyId],
              };
            }
          }
        });

        if (typeCondition && companySellerConditionDealLevel && companyBuyerConditionDealLevel) {
          //YEARS
          years[year] = true;
        }
        if (yearCondition && companySellerConditionDealLevel && companyBuyerConditionDealLevel) {
          //TYPES
          if (deal.dealType) {
            types[deal.dealType] = {
              id: deal.dealType,
              displayName: deal.dealType,
              isChosen: !!typeNamesEnabled[deal.dealType],
              //todo temporary do this proper way
              group: getGroupForDealType(deal.dealType, typeGroupChosenList),
            };
          }
        }
      }
    }
  }

  return {
    pageContentDataResult: pageContentData,
    years,
    types,
    countriesObject,
    companySellers,
    companyBuyers,
    assets,
  };
};
