import { EXPORT_MA_TRANSACTIONS_ENDPOINT } from '../operations';
import { downloadXlsx } from '../../../../Downloads/helpers';
import { useCallback } from 'react';
import { useFilters } from '../../../FiltersContext';

export const useDownloadMaTransactions = () => {
  const { filterVariables } = useFilters();

  const downloadMaTransactions = useCallback(async () => {
    const downloadName = `M_A_Transactions_Data.xlsx`;
    const result = await downloadXlsx(
      EXPORT_MA_TRANSACTIONS_ENDPOINT,
      filterVariables,
      downloadName,
    );

    let errors = [];
    if (!result.ok) {
      const json = await result.json();
      errors = json?.errors || [];
    }

    return { ok: result.ok, status: result.status, errors };
  }, [filterVariables]);

  return { downloadMaTransactions };
};
