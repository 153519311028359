import { gql } from '@apollo/client';

export const MA_FILTERS_SUMMARY_QUERY = gql`
  query maTransactionsSummary {
    maTransactionsSummary {
      startYear
      endYear
      minValuation
      maxValuation
      minReserves
      maxReserves
      minProduction
      maxProduction
      minEmissions
      maxEmissions
    }
  }
`;
