import { gql } from '@apollo/client';

export const EXPORT_MA_TRANSACTIONS_ENDPOINT = 'm-a/export-transactions';

export const MA_TRANSACTIONS_QUERY = gql`
  query maTransactions(
    $filters: MaFilters
    $page: Int
    $perPage: Int
    $order: String
    $orderBy: String
  ) {
    maTransactions(
      filters: $filters
      page: $page
      perPage: $perPage
      order: $order
      orderBy: $orderBy
    ) {
      totalCount
      transactions {
        id
        assetName
        shoreStatus
        workingInterest
        weaWorkingInterestReserves
        weaWorkingInterestProduction
        weaReservesAcquisitionCost
        weaWorkingInterestValuation
        weaWorkingInterestLiquidsReserves
        weaWorkingInterestGasReserves
        weaPriceFlowingBarrel
        weaWorkingInterestLiquidsProduction
        weaWorkingInterestGasProduction
        weaCurrentEmissionsIntensity
        weaRemainingEmissionsIntensity

        country {
          id
          displayName
          region {
            id
            displayName
          }
        }

        maCompanyTransaction {
          id

          buyerCompany {
            id
            name
          }

          sellerCompany {
            id
            name
          }

          soldCompany {
            id
            name
          }

          maDeal {
            id
            dealType
            effectiveDate
            comment
            considerationMmusd
          }
        }
      }
    }
  }
`;
